import { Type } from "../../../../../harmony/core";
import { PublicationTemplateForm } from "../components/publication-template-form/publication-template-form";
import { PublicationTemplatePreview } from "../components/publication-template-preview/publication-template-preview";
import { PublicationTemplateRulesBoxComponent } from "../components/publication-template-rules-box/publication-template-rules-box.component";
import { FacebookCarouselFormComponent } from "./facebook-carousel/facebook-carousel-form/facebook-carousel-form.component";
import { FacebookCarouselPreviewComponent } from "./facebook-carousel/facebook-carousel-preview/facebook-carousel-preview.component";
import { FacebookCarouselRulesBoxComponent } from "./facebook-carousel/facebook-carousel-rules-box/facebook-carousel-rules-box.component";
import {
  FacebookCarouselFormToApiMapper,
  FacebookCarouselFormToPreviewMapper,
} from "./facebook-carousel/facebook-carousel.mapper";
import { InstagramStoryFormComponent } from "./instagram-story/instagram-story-form/instagram-story-form.component";
import { InstagramStoryPreviewComponent } from "./instagram-story/instagram-story-preview/instagram-story-preview.component";
import { InstagramStoryRulesBoxComponent } from "./instagram-story/instagram-story-rules-box/instagram-story-rules-box.component";
import {
  InstagramStoryFormToApiMapper,
  InstagramStoryFormToPreviewMapper,
} from "./instagram-story/instagram-story.mapper";
import { PublicationTemplateType } from "../data/entities/publication-templates.entity";
import { PublicationTemplateFields } from "../data/entities/publication-template-fields.entity";
import { FormGroupType } from "../../../../../harmony/angular";
import {
  FormToApiMapper,
  FormToPreviewMapper,
} from "../domain/mappers/publication-template.mapper";

export interface PublicationTemplateConfig<
  PubTplType extends PublicationTemplateType = any,
  FormGroup extends FormGroupType<FormGroup> = any,
  CreateDto = any,
  Fields extends PublicationTemplateFields = any,
> {
  type: PubTplType;
  path: string;
  form: Type<PublicationTemplateForm<FormGroup>>;
  formToApi: Type<FormToApiMapper<FormGroup, CreateDto>>;
  formToPreview: Type<FormToPreviewMapper<FormGroup, PubTplType>>;
  preview: Type<PublicationTemplatePreview<PubTplType>>;
  rulesBox: Type<PublicationTemplateRulesBoxComponent<Fields>>;
}

// TODO: This is not type-checking correctly, we need to somehow wire `type` with the other properties generics
export const PUBLICATION_TEMPLATES: PublicationTemplateConfig[] = [
  {
    type: PublicationTemplateType.InstagramStory,
    path: "instagram-story",
    form: InstagramStoryFormComponent,
    formToApi: InstagramStoryFormToApiMapper,
    formToPreview: InstagramStoryFormToPreviewMapper,
    preview: InstagramStoryPreviewComponent,
    rulesBox: InstagramStoryRulesBoxComponent,
  },
  {
    type: PublicationTemplateType.FacebookCarousel,
    path: "facebook-carousel",
    form: FacebookCarouselFormComponent,
    formToApi: FacebookCarouselFormToApiMapper,
    formToPreview: FacebookCarouselFormToPreviewMapper,
    preview: FacebookCarouselPreviewComponent,
    rulesBox: FacebookCarouselRulesBoxComponent,
  },
];

export const PUBLICATION_TEMPLATES_MAP: Map<
  PublicationTemplateType,
  PublicationTemplateConfig
> = new Map(PUBLICATION_TEMPLATES.map((config) => [config.type, config]));
