import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { mapFileToMedia } from "../../../../features/media/domain/mappers/file-to-media.mapper";
import { RecommendedScheduleEntityToRecommendedScheduleMapper } from "../../../../features/media/domain/mappers/recommended-schedules.mapper";
import { Media } from "../../../../features/media/domain/models/media";
import { PageMapper } from "../../../../features/shared/domain/mappers/page.mapper";
import { Page } from "../../../../features/shared/domain/models/page";
import { StrictApiRequestService } from "../../../../shared/http/strict-api-request.service";
import { AuthNetworkService } from "../../../../shared/services/api/auth.network.service";
import {
  PublicationTemplate,
  RecommendedSchedule,
} from "../domain/models/publication-template";
import { PublicationTemplateEntityToPublicationTemplateMapper } from "../domain/mappers/publication-template.mapper";
import { PublicationTemplateFields } from "./entities/publication-template-fields.entity";
import {
  PublicationTemplateEntity,
  PublicationTemplateType,
  RecommendedScheduleParser,
} from "./entities/publication-templates.entity";

@Injectable()
export class PublicationTemplateService {
  private readonly apiRequestService: StrictApiRequestService;
  private readonly SCHEDULES_PATH = "/recommended-schedules";

  constructor(
    @Inject("API_GATEWAY_URL")
    API_GATEWAY_URL: string,
    httpClient: HttpClient,
    authNetworkService: AuthNetworkService,
  ) {
    this.apiRequestService = new StrictApiRequestService(
      `${API_GATEWAY_URL}/publication-template`,
      httpClient,
      authNetworkService,
    );
  }

  public getTypesByCampaign(
    campaignId: number,
  ): Promise<PublicationTemplateType[]> {
    return this.apiRequestService
      .path(`/types`)
      .setQueryParams({ campaignId: campaignId })
      .get();
  }

  public getFields(
    type: PublicationTemplateType,
  ): Promise<PublicationTemplateFields> {
    return this.apiRequestService.path(`/${type}/fields`).get();
  }

  public async uploadMedia(
    type: PublicationTemplateType,
    field: string,
    file: File,
  ): Promise<Media> {
    const data = new FormData();
    data.append("file", file);

    const mediaId = await this.apiRequestService
      .path(`/media`)
      .setQueryParams({ type: type, field: field })
      .setBody(data)
      .post()
      .then((res) => res.id);

    // the api returns just an id, so we need
    // to use the file sent to create a Media object
    return mapFileToMedia(file, mediaId);
  }

  public create(
    brandId: number,
    campaignId: number,
    type: PublicationTemplateType,
    data: PublicationTemplateEntity,
  ): Promise<PublicationTemplate> {
    return this.apiRequestService
      .path(`/${type}`)
      .setBody({
        ...data,
        brandId: brandId,
        campaignId: campaignId,
      })
      .setMapper(new PublicationTemplateEntityToPublicationTemplateMapper())
      .post();
  }

  public update(
    publicationTemplate: PublicationTemplate,
    data: PublicationTemplateEntity,
  ): Promise<PublicationTemplate> {
    return this.apiRequestService
      .path(
        `/${publicationTemplate.publicationTemplateType}/${publicationTemplate.id}`,
      )
      .setBody(data)
      .setMapper(new PublicationTemplateEntityToPublicationTemplateMapper())
      .put();
  }

  public get(id: string): Promise<PublicationTemplate> {
    return (
      this.apiRequestService
        .path(`bff/${id}`)
        // .setParser(PublicationTemplateParser)
        .setMapper(new PublicationTemplateEntityToPublicationTemplateMapper())
        .get()
    );
  }

  public getAllByCampaign(
    campaignId: number,
    page = 1,
    size = 20,
  ): Promise<Page<PublicationTemplate>> {
    return (
      this.apiRequestService
        .path("bff")
        .setQueryParams({ campaignId: campaignId, page: page, size: size })
        //      .setParser(PublicationTemplatePageParser)
        .setMapper(
          new PageMapper(
            new PublicationTemplateEntityToPublicationTemplateMapper(),
          ),
        )
        .get()
    );
  }

  public delete(id: string): Promise<void> {
    return this.apiRequestService.path(`/${id}`).delete();
  }

  public updateRecommendedSchedule(
    schedule: RecommendedSchedule,
  ): Promise<RecommendedSchedule> {
    return this.apiRequestService
      .path(`${this.SCHEDULES_PATH}/${schedule.id}`)
      .setBody({
        publication: schedule.publicationTemplateId,
        start: Math.floor(schedule.startDate.getTime() / 1000),
        endDate: schedule.endDate
          ? Math.floor(schedule.endDate.getTime() / 1000)
          : undefined,
      })
      .setParser(RecommendedScheduleParser)
      .setMapper(new RecommendedScheduleEntityToRecommendedScheduleMapper())
      .put();
  }

  public createRecommendedSchedule(
    publicationTemplateId: string,
    startDate: Date,
    endDate?: Date,
  ): Promise<RecommendedSchedule> {
    return this.apiRequestService
      .path(this.SCHEDULES_PATH)
      .setBody({
        publication_template_id: publicationTemplateId,
        start: Math.floor(startDate.getTime() / 1000),
        end: endDate ? Math.floor(endDate.getTime() / 1000) : undefined,
      })
      .setParser(RecommendedScheduleParser)
      .setMapper(new RecommendedScheduleEntityToRecommendedScheduleMapper())
      .post();
  }

  public deleteRecommendedSchedule(id: string): Promise<void> {
    return this.apiRequestService.path(`${this.SCHEDULES_PATH}/${id}`).delete();
  }

  public getAvailablePublicationTypes(): string[] {
    return environment.features.betaCampaignType.publicationTypes;
  }
}
