import { Injectable } from "@angular/core";
import { MediaType } from "../../../../../../features/media/data/entities/media.entity";
import { DateRange } from "../../../../../../shared/services/date.service";
import { PublicationTemplateType } from "../../../data/entities/publication-templates.entity";
import {
  FacebookCarousel,
  InstagramStory,
  PublicationTemplate,
  RecommendedSchedule,
} from "../../../domain/models/publication-template";
import { PublicationEventInput } from "./publication-event-input";
import "../../../../../../shared/extensions/date.extensions";

enum SocialMediaColor {
  Facebook = "#EFF6FF",
  Instagram = "#FBF1FC",
  LinkedIn = "#ECF8FF",
  Google = "#F0FDF4",
}

enum SocialMediaBorderColor {
  Facebook = "#4A90E2",
  Instagram = "#A329A6",
  LinkedIn = "#0077B7",
  Google = "#16A34A",
}

export const mapPublicationTemplateToCalendarEvents = (
  publication: PublicationTemplate,
  schedule: RecommendedSchedule,
): PublicationEventInput => ({
  id: `${schedule.id}`,
  start: schedule.startDate.toISOString(),
  end: schedule.endDate?.toISOString(),
  allDay: true,
  durationEditable: false,
  eventResizableFromStart: false,
  eventDurationEditable: false,
  extendedProps: {
    typeText: publication.publicationTemplateType,
    publicationTemplateId: publication.id,
    platform: publication.platform,
    isAd: false,
  },
});

export const mapInstagramPublicationToCalendarEvent = (
  instagramStory: InstagramStory, // | instagramReel ...,
  schedule: RecommendedSchedule,
): PublicationEventInput => {
  const baseConfig = mapPublicationTemplateToCalendarEvents(
    instagramStory,
    schedule,
  );
  return {
    ...baseConfig,
    backgroundColor: SocialMediaColor.Instagram,
    borderColor: SocialMediaBorderColor.Instagram,
    extendedProps: {
      ...baseConfig.extendedProps,
      icon: "ic_instagram.svg",
    },
  };
};

export const mapFacebookPublicationToCalendarEvent = (
  facebookPublication: FacebookCarousel, // | FacebookReel ...,
  schedule: RecommendedSchedule,
): PublicationEventInput => {
  const baseConfig = mapPublicationTemplateToCalendarEvents(
    facebookPublication,
    schedule,
  );
  return {
    ...baseConfig,
    backgroundColor: SocialMediaColor.Facebook,
    borderColor: SocialMediaBorderColor.Facebook,
    extendedProps: {
      ...baseConfig.extendedProps,
      icon: "ic_facebook.svg",
    },
  };
};

interface IPublicationTemplateCalendarEventFactory {
  create(
    publicationTemplate: PublicationTemplate,
    campaignDateRange: DateRange,
  ): PublicationEventInput[];
}

@Injectable()
export class InstagramStoryCalendarEventFactory
  implements IPublicationTemplateCalendarEventFactory
{
  public create(
    instagramStory: InstagramStory,
    campaignDateRange: DateRange,
  ): PublicationEventInput[] {
    return instagramStory.recommendedSchedules.map((schedule) => {
      const instagramEventConfig = mapInstagramPublicationToCalendarEvent(
        instagramStory,
        schedule,
      );

      return {
        ...instagramEventConfig,
        extendedProps: {
          ...instagramEventConfig.extendedProps,
          image:
            instagramStory.media.mediaType === MediaType.Video
              ? instagramStory.media.thumbnailLink
              : instagramStory.media.link,
          title: "publicationTemplate.thumbnail.instagramStory.name",
        },
        editable:
          schedule.startDate.isInDayRange(campaignDateRange) &&
          schedule.startDate.isTodayOrFutureDay(),
      };
    });
  }
}

@Injectable()
export class FacebookCarouselCalendarEventFactory
  implements IPublicationTemplateCalendarEventFactory
{
  public create(
    facebookCarousel: FacebookCarousel,
    campaignDateRange: DateRange,
  ): PublicationEventInput[] {
    return facebookCarousel.recommendedSchedules.map((schedule) => {
      const instagramEventConfig = mapFacebookPublicationToCalendarEvent(
        facebookCarousel,
        schedule,
      );

      return {
        ...instagramEventConfig,
        extendedProps: {
          ...instagramEventConfig.extendedProps,
          image: facebookCarousel.slides[0].media.thumbnailLink,
          title: "publicationTemplate.thumbnail.facebookCarousel.name",
        },
        editable:
          schedule.startDate.isInDayRange(campaignDateRange) &&
          schedule.startDate.isTodayOrFutureDay(),
      };
    });
  }
}

@Injectable()
export class PublicationTemplateCalendarEventFactory
  implements IPublicationTemplateCalendarEventFactory
{
  public create(
    publicationTemplate: PublicationTemplate,
    campaignDateRange: DateRange,
  ): PublicationEventInput[] {
    let factory: IPublicationTemplateCalendarEventFactory;

    switch (publicationTemplate.publicationTemplateType) {
      case PublicationTemplateType.InstagramStory:
        factory = new InstagramStoryCalendarEventFactory();
        break;
      case PublicationTemplateType.FacebookCarousel:
        factory = new FacebookCarouselCalendarEventFactory();
        break;
      default:
        const pt: never = publicationTemplate;
        throw new Error(`Not implemented: ${pt}`);
    }

    return factory.create(publicationTemplate, campaignDateRange);
  }
}
