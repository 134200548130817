import { assertUnreachable } from "../../admin/shared/functions/assert-unreachable";
import { PublicationTemplateService } from "../../brand/brand-campaign/publication-template/data/publication-template.service";
import {
  FilterType,
  SelectorOption,
} from "../../shared/components/filtering/filter.interfaces";
import { SelectorOptionsService } from "../../shared/components/filtering/selector-options.service";
import { Page } from "../shared/domain/models/page";
import { GetCampaignOptionsInteractor } from "./interactors/get-campaign-options.interactor";
import { GetCreatorOptionsInteractor } from "./interactors/get-creator-options.interactor";
import { GetPublicationTemplateStatusOptionsInteractor } from "./interactors/get-publication-template-status-options.interactor";
import { GetPublisherOptionsInteractor } from "./interactors/get-publisher-options.interactor";
import { GetPublicationTemplateTypeOptionsInteractor } from "./interactors/get-publication-template-type-options.interactor";

export interface GetSelectorOptionsInteractor {
  execute(...filters: any): Promise<Page<SelectorOption>>;
}

export class SelectorOptionsComponent {
  public static provideGetOptionsInteractorByType(
    type: FilterType,
    selectorOptionsService: SelectorOptionsService,
    publicationTemplateService: PublicationTemplateService,
  ): GetSelectorOptionsInteractor {
    switch (type) {
      case FilterType.Campaign:
        return SelectorOptionsComponent.provideGetCampaignOptionsInteractor(
          selectorOptionsService,
        );
      case FilterType.Creator:
        return SelectorOptionsComponent.provideGetCreatorOptionsInteractor(
          selectorOptionsService,
        );
      case FilterType.Publisher:
        return SelectorOptionsComponent.provideGetPublisherOptionsInteractor(
          selectorOptionsService,
        );
      case FilterType.PublicationTemplateType:
        return SelectorOptionsComponent.provideGetPublicationTemplateTypeOptionsInteractor(
          publicationTemplateService,
        );
      case FilterType.PublicationTemplateStatus:
        return SelectorOptionsComponent.provideGetPublicationTemplateStatusOptionsInteractor();
      case FilterType.TimePeriod:
        return SelectorOptionsComponent.provideGetCampaignOptionsInteractor(
          selectorOptionsService,
        );
      default:
        return assertUnreachable(type as never);
    }
  }

  public static provideGetCampaignOptionsInteractor(
    selectorOptionsService: SelectorOptionsService,
  ): GetCampaignOptionsInteractor {
    return new GetCampaignOptionsInteractor(selectorOptionsService);
  }

  public static provideGetCreatorOptionsInteractor(
    selectorOptionsService: SelectorOptionsService,
  ): GetCreatorOptionsInteractor {
    return new GetCreatorOptionsInteractor(selectorOptionsService);
  }

  public static provideGetPublisherOptionsInteractor(
    selectorOptionsService: SelectorOptionsService,
  ): GetPublisherOptionsInteractor {
    return new GetPublisherOptionsInteractor(selectorOptionsService);
  }

  public static provideGetPublicationTemplateTypeOptionsInteractor(
    publicationTemplateService: PublicationTemplateService,
  ): GetPublicationTemplateTypeOptionsInteractor {
    return new GetPublicationTemplateTypeOptionsInteractor(
      publicationTemplateService,
    );
  }

  public static provideGetPublicationTemplateStatusOptionsInteractor(): GetPublicationTemplateStatusOptionsInteractor {
    return new GetPublicationTemplateStatusOptionsInteractor();
  }
}
