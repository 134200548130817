@if (!data.media) {
  <div class="empty-state-preview">
    <p>
      {{
        "publicationTemplatePost.uploadContentToMediaFilesForPreview"
          | translate
      }}
    </p>
  </div>
} @else if (data.media.mediaType === "image") {
  <img class="overlay-media" [src]="data.media.link" loading="lazy" />
} @else if (data.media.mediaType === "video") {
  @if (data.media.thumbnailLink) {
    <img
      class="overlay-media"
      [src]="data.media.thumbnailLink"
      loading="lazy"
    />
  } @else {
    <video
      class="overlay-media"
      autoplay
      loop
      controlslist="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
    >
      <source [src]="data.media.link" type="video/mp4" />
      <source [src]="data.media.link" type="video/ogg" />
    </video>
  }
}
<img
  class="overlay-media opacity"
  src="/assets/images/publication-template/preview/as_story_preview@2x.png"
  loading="lazy"
/>
