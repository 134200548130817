import {
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Output,
  signal,
  Signal,
} from "@angular/core";
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from "@angular/material/autocomplete";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { TranslateModule } from "@ngx-translate/core";
import { PublicationTemplateService } from "../../../../brand/brand-campaign/publication-template/data/publication-template.service";
import {
  GetSelectorOptionsInteractor,
  SelectorOptionsComponent,
} from "../../../../features/selector-options/selector-options.providers";
import { SharedPipesModule } from "../../../pipes/shared-pipes.module";
import { AutocompleteSelectorComponent } from "../autocomplete-selector/autocomplete-selector.component";
import { FilterOutput, FilterType } from "../filter.interfaces";
import { SelectedChipComponent } from "../selected-chip/selected-chip.component";
import { SelectorOptionsService } from "../selector-options.service";
import { TimePeriodSelectorComponent } from "../time-period-selector/time-period-selector.component";

@Component({
  standalone: true,
  selector: "app-aggregated-filter",
  templateUrl: "./aggregated-filter.component.html",
  styleUrl: "./aggregated-filter.component.scss",
  imports: [
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    MatProgressSpinner,
    MatLabel,
    MatFormField,
    MatInput,
    AutocompleteSelectorComponent,
    MatIcon,
    SelectedChipComponent,
    SharedPipesModule,
    TranslateModule,
    TimePeriodSelectorComponent,
  ],
  providers: [SelectorOptionsService],
})
export class AggregatedFilterComponent {
  public readonly filterTypes = input.required<FilterType[]>();
  public readonly fixedFilters = input<FilterOutput[]>();
  @Output() public readonly filtersChanged = new EventEmitter<FilterOutput[]>();
  protected readonly FilterType = FilterType;
  protected readonly filterInteractors: Signal<GetSelectorOptionsInteractor[]> =
    ((
      selectorOptionsService: SelectorOptionsService,
      publicationTemplateService,
    ) =>
      computed(() =>
        this.filterTypes().map((filterType) =>
          SelectorOptionsComponent.provideGetOptionsInteractorByType(
            filterType,
            selectorOptionsService,
            publicationTemplateService,
          ),
        ),
      ))(inject(SelectorOptionsService), inject(PublicationTemplateService));

  protected readonly areFiltersOpen = signal(false);
  protected readonly activeFilters = signal<FilterOutput[]>([]);
  protected readonly selectedCampaignId = computed(() => {
    let selectedCampaign = this.fixedFilters()?.find(
      (filter) => filter.type === FilterType.Campaign,
    );

    if (selectedCampaign) {
      return selectedCampaign.value.id;
    }

    selectedCampaign = this.activeFilters().find(
      (filter) => filter.type === FilterType.Campaign,
    );

    return selectedCampaign
      ? (selectedCampaign?.value.id as number)
      : undefined;
  });

  protected onActionToggleFilters(): void {
    this.areFiltersOpen.update((areFiltersOpen) => !areFiltersOpen);
  }

  protected onActionOptionSelected(filters: FilterOutput[]): void {
    if (filters.length && filters[0].type === FilterType.TimePeriod) {
      this.activeFilters.update((fs) =>
        fs.filter((filter) => filter.type !== FilterType.TimePeriod),
      );
    }

    if (
      filters.some((filter) =>
        this.activeFilters().some(
          (f) => f.value.id === filter.value.id && f.type === filter.type,
        ),
      )
    ) {
      return;
    }

    this.activeFilters.update((activeFilters) => [
      ...activeFilters,
      ...filters,
    ]);
    this.filtersChanged.emit(this.activeFilters());
  }

  protected onActionOptionRemoved(filter: FilterOutput): void {
    this.activeFilters.update((activeFilters) =>
      activeFilters.filter((activeFilter) => activeFilter !== filter),
    );
    this.filtersChanged.emit(this.activeFilters());
  }

  public reset(): void {
    this.activeFilters.set([]);
    this.filtersChanged.emit(this.activeFilters());
  }
}
