import { Message } from "../../../../../shared/types/message.types";
import {
  Constraint,
  Rules,
} from "../../data/entities/publication-template-rules.entity";
import { maxFileSizeInBytes } from "./client-side-validators/max-file-size-in-bytes";

export type FileValidator<C = any> = (
  file: File,
  constraint: Constraint<C>,
) => Message | undefined;

export class ValidateFileClientSideInteractor {
  private readonly validators: Record<string, FileValidator> = {
    maxFileSizeInBytes: maxFileSizeInBytes,
  };

  public execute(file: File, rules: Rules): Message[] {
    return Object.entries(rules)
      .map(([key, constraint]) => {
        const validator = this.validators[key];

        if (validator) {
          return validator(file, constraint);
        }
      })
      .filter((validation): validation is Message => !!validation);
  }
}
