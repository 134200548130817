import { Mapper } from "../../../../../harmony/core";
import { RecommendedScheduleEntity } from "../../../../brand/brand-campaign/publication-template/data/entities/publication-templates.entity";
import { RecommendedSchedule } from "../../../../brand/brand-campaign/publication-template/domain/models/publication-template";

export class RecommendedScheduleEntityToRecommendedScheduleMapper
  implements Mapper<RecommendedScheduleEntity, RecommendedSchedule>
{
  public map(from: RecommendedScheduleEntity): RecommendedSchedule {
    return new RecommendedSchedule(
      from.id,
      from.publication_template_id,
      new Date(from.start * 1000),
      from.end ? new Date(from.end * 1000) : undefined,
    );
  }
}
