import { assert } from "../../../../../shared/utils/assert";
import { InstagramStoryForm } from "./instagram-story-form/instagram-story-form.component";
import { InstagramStoryCreateDto } from "./instagram-story.create-dto";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";
import {
  FormToApiMapper,
  FormToPreviewMapper,
} from "../../domain/mappers/publication-template.mapper";
import { FormValue } from "../../../../../../harmony/angular";
import { PartialPublicationTemplate } from "../../domain/models/publication-template";

export class InstagramStoryFormToApiMapper
  implements FormToApiMapper<InstagramStoryForm, InstagramStoryCreateDto>
{
  public map(form: FormValue<InstagramStoryForm>): InstagramStoryCreateDto {
    assert(form.media !== undefined && form.media.id, "Media ID is required");

    return {
      mediaId: form.media.id,
    };
  }
}

export class InstagramStoryFormToPreviewMapper
  implements
    FormToPreviewMapper<
      InstagramStoryForm,
      PublicationTemplateType.InstagramStory
    >
{
  public map(
    form: FormValue<InstagramStoryForm>,
  ): PartialPublicationTemplate<PublicationTemplateType.InstagramStory> {
    return {
      publicationTemplateType: PublicationTemplateType.InstagramStory,
      media: form.media,
    };
  }
}
