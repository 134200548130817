import { PublicationTemplateService } from "../../data/publication-template.service";

export class DeleteRecommendedScheduleInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
  ) {}

  public async execute(scheduleId: string): Promise<void> {
    return this.publicationTemplateService.deleteRecommendedSchedule(
      scheduleId,
    );
  }
}
