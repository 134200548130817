import { PublicationTemplateFields } from "../../data/entities/publication-template-fields.entity";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";
import { PublicationTemplateService } from "../../data/publication-template.service";

export class GetPublicationTemplateFieldsInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
  ) {}

  public async execute(
    type: PublicationTemplateType,
  ): Promise<PublicationTemplateFields> {
    return this.publicationTemplateService.getFields(type);
  }
}
