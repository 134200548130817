import { SelectorOption } from "../../../shared/components/filtering/filter.interfaces";
import { SelectorOptionsService } from "../../../shared/components/filtering/selector-options.service";
import { Page } from "../../shared/domain/models/page";

export class GetCampaignOptionsInteractor {
  constructor(
    private readonly selectorOptionsService: SelectorOptionsService,
  ) {}

  public async execute(
    pageIndex: number,
    pageSize: number,
    searchKey: string,
  ): Promise<Page<SelectorOption>> {
    return this.selectorOptionsService.getCampaign(
      pageIndex,
      pageSize,
      searchKey,
    );
  }
}
