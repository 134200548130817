import { Component, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule, RouterOutlet } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  standalone: true,
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
  imports: [TranslateModule, RouterOutlet, MatIconModule, RouterModule],
})
export class PageHeaderComponent {
  @Input() public title?: string;
  @Input() public backLink?: string;
}
