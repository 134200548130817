import { NgModule } from "@angular/core";
import { angularProvidersBuilder } from "../../../../../harmony/angular";
import { NotificationService } from "../../../../shared/services/notification.service";
import { PublicationTemplateService } from "../data/publication-template.service";
import { CreatePublicationTemplateInteractor } from "./interactors/create-publication-template.interactor";
import { CreateRecommendedScheduleInteractor } from "./interactors/create-recommended-schedule.interactor";
import { DeleteRecommendedScheduleInteractor } from "./interactors/delete-recommended-schedule.interactor";
import { GetPublicationTemplateFieldsInteractor } from "./interactors/get-publication-template-fields.interactor";
import { GetPublicationTemplateTypesByCampaignInteractor } from "./interactors/get-publication-template-types-by-campaign.interactor";
import { GetPublicationTemplatesInteractor } from "./interactors/get-publication-templates.interactor";
import { UpdatePublicationTemplateInteractor } from "./interactors/update-publication-template.interactor";
import { UpdateRecommendedScheduleInteractor } from "./interactors/update-recommended-schedule.interactor";
import {
  PublicationTemplatesDefaultProvider,
  PublicationTemplateProvider,
} from "./publication-template.provider";
import { DeletePublicationTemplateInteractor } from "./interactors/delete-publication-template.interactor";
import { GetPublicationTemplateInteractor } from "./interactors/get-publication-template.interactor";

@NgModule({
  providers: angularProvidersBuilder({
    provide: PublicationTemplateProvider,
    deps: [PublicationTemplateService, NotificationService],
    useFactory: (
      publicationTemplatesService: PublicationTemplateService,
      notificationService: NotificationService,
    ) =>
      new PublicationTemplatesDefaultProvider(
        publicationTemplatesService,
        notificationService,
      ),
  })
    .add(CreatePublicationTemplateInteractor, (p) =>
      p.provideCreatePublicationTemplate(),
    )
    .add(UpdatePublicationTemplateInteractor, (p) =>
      p.provideUpdatePublicationTemplate(),
    )
    .add(DeletePublicationTemplateInteractor, (p) =>
      p.provideDeletePublicationTemplate(),
    )
    .add(GetPublicationTemplateInteractor, (p) =>
      p.provideGetPublicationTemplate(),
    )
    .add(GetPublicationTemplateFieldsInteractor, (p) =>
      p.provideGetPublicationTemplateFields(),
    )
    .add(GetPublicationTemplatesInteractor, (p) =>
      p.provideGetPublicationTemplates(),
    )
    .add(GetPublicationTemplateTypesByCampaignInteractor, (p) =>
      p.provideGetPublicationTemplateTypesByCampaign(),
    )
    .add(CreateRecommendedScheduleInteractor, (p) =>
      p.provideCreateRecommendedSchedule(),
    )
    .add(UpdateRecommendedScheduleInteractor, (p) =>
      p.provideUpdateRecommendedSchedule(),
    )
    .add(DeleteRecommendedScheduleInteractor, (p) =>
      p.provideDeleteRecommendedSchedule(),
    )
    .build(),
})
export class PublicationTemplateProviderModule {}
