import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SentryService } from "./sentry.service";

@Injectable({ providedIn: "root" })
export class NotFoundService {
  constructor(
    private readonly router: Router,
    private readonly sentryService: SentryService,
  ) {}

  public redirect(id: string, route = "/not-found"): void {
    this.router.navigate([route]);
    this.sentryService.captureException(
      new Error(`Not Found Redirect (${id})`),
    );
  }
}
