import { NgxUploaderModule, UploadOutput } from "@angular-ex/uploader";
import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { assert } from "../../utils/assert";

@Component({
  standalone: true,
  selector: "app-file-input",
  templateUrl: "./file-input.component.html",
  styleUrls: ["./file-input.component.scss"],
  imports: [MatIconModule, TranslateModule, NgClass, NgxUploaderModule],
})
export class FileInputComponent {
  @Input() public accept?: string[];
  @Input() public isDisabled = false;
  @Input() public multiple = false;
  @Output() public selected = new EventEmitter<File[]>();

  protected isHovering = false;

  private filesQueue: File[] = [];

  public onActionFileAdded(event: Event): void {
    if (this.isDisabled) {
      return;
    }

    this.assertIsHtmlInputElement(event.target);

    const target = event.target;
    const files = Array.from(target.files as ArrayLike<File>);
    this.selected.emit(files);

    target.value = "";
  }

  private assertIsHtmlInputElement(
    el: EventTarget | null,
  ): asserts el is HTMLInputElement {
    if (!(el instanceof HTMLInputElement)) {
      throw new Error("Element is not an input");
    }
  }

  protected onUploadOutput(output: UploadOutput): void {
    if (this.isDisabled) {
      return;
    }

    this.isHovering = output.type === "dragOver";

    if (output.type === "addedToQueue") {
      assert(output.file?.nativeFile, "File must be defined");
      this.filesQueue.push(output.file.nativeFile);
    } else if (output.type === "allAddedToQueue") {
      this.selected.emit(this.filesQueue);
      this.filesQueue = [];
    }
  }
}
