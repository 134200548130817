import { NgModule } from "@angular/core";
import { angularProvidersBuilder } from "../../../harmony/angular";
import { BrandCampaignInvitePartnersService } from "../../brand/brand-campaign/brand-campaign-invite-partners/brand-campaign-invite-partners.service";
import { BrandCampaignPartnersService } from "../../brand/brand-campaign/shared/services/brand-campaign-partners.service";
import {
  BrandCampaignDefaultProvider,
  BrandCampaignProvider,
} from "./brand-campaign.provider";
import { CancelPartnersSheetImportInteractor } from "./domain/interactors/cancel-partners-sheet-import.interactor";
import { ExtendPartnersSheetImportTimeInteractor } from "./domain/interactors/extend-partners-sheet-import-time.interactor";
import { GetCampaignInvitedPartnersInteractor } from "./domain/interactors/get-campaign-invited-partners.interactor";
import { GetPartnersSheetProcessingProgressInteractor } from "./domain/interactors/get-partners-sheet-processing-progress.interactor";
import { GetPartnersSheetProcessingResultInteractor } from "./domain/interactors/get-partners-sheet-processing-result.interactor";
import { GetPartnersSheetStateInteractor } from "./domain/interactors/get-partners-sheet-state.interactor";
import { ImportPartnersInteractor } from "./domain/interactors/import-partners.interactor";
import { UploadPartnersSheetInteractor } from "./domain/interactors/upload-partners-sheet.interactor";

@NgModule({
  providers: angularProvidersBuilder({
    provide: BrandCampaignProvider,
    deps: [BrandCampaignPartnersService, BrandCampaignInvitePartnersService],
    useFactory: (
      brandCampaignPartnersService: BrandCampaignPartnersService,
      invitePartnersService: BrandCampaignInvitePartnersService,
    ) =>
      new BrandCampaignDefaultProvider(
        brandCampaignPartnersService,
        invitePartnersService,
      ),
  })
    .add(CancelPartnersSheetImportInteractor, (p) =>
      p.provideCancelPartnersSheetImport(),
    )
    .add(ExtendPartnersSheetImportTimeInteractor, (p) =>
      p.provideExtendPartnersSheetImportTime(),
    )
    .add(GetCampaignInvitedPartnersInteractor, (p) =>
      p.provideGetCampaignInvitedPartners(),
    )
    .add(GetPartnersSheetProcessingProgressInteractor, (p) =>
      p.provideGetPartnersSheetProcessingProgress(),
    )
    .add(GetPartnersSheetProcessingResultInteractor, (p) =>
      p.provideGetPartnersSheetProcessingResult(),
    )
    .add(GetPartnersSheetStateInteractor, (p) =>
      p.provideGetPartnersSheetState(),
    )
    .add(ImportPartnersInteractor, (p) => p.provideImportPartners())
    .add(UploadPartnersSheetInteractor, (p) => p.provideUploadPartnersSheet())
    .build(),
})
export class BrandCampaignProviderModule {}
