import { Platform } from "../../../../../shared/enums/campaignPost.enums";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";

export interface PublicationTemplatesByPlatform {
  readonly platform: Platform;
  readonly types: PublicationTemplateType[];
}

export const PublicationTemplateTypesVm = (
  availableTypes: string[],
): PublicationTemplatesByPlatform[] => {
  const vm = [
    {
      platform: Platform.Facebook,
      types: [PublicationTemplateType.FacebookCarousel].filter((type) =>
        availableTypes.includes(type),
      ),
    },
    {
      platform: Platform.Instagram,
      types: [PublicationTemplateType.InstagramStory].filter((type) =>
        availableTypes.includes(type),
      ),
    },
  ];

  return vm.filter((platform) => platform.types.length > 0);
};
