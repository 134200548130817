import { Component, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { Message } from "../../types/message.types";

@Component({
  standalone: true,
  selector: "app-error-message",
  templateUrl: "./error-message.component.html",
  styleUrls: ["./error-message.component.scss"],
  imports: [MatIconModule, TranslateModule],
})
export class ErrorMessageComponent {
  @Input() public error?: Message;
  @Input() public key?: string;
  @Input() public prefix = "";
}
