import {
  Component,
  ComponentRef,
  inject,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { NotFoundService } from "../../../../../shared/services/not-found.service";
import { assert } from "../../../../../shared/utils/assert";
import { PartialPublicationTemplate } from "../../domain/models/publication-template";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";
import { PUBLICATION_TEMPLATES } from "../../publication-templates/publication-templates.config";
import { PublicationTemplatePreview } from "./publication-template-preview";

@Component({
  selector: "app-publication-template-preview",
  templateUrl: "./publication-template-preview.component.html",
  styleUrl: "./publication-template-preview.component.scss",
})
export class PublicationTemplatePreviewComponent implements OnInit {
  private _data!: PartialPublicationTemplate<any>;
  @Input({ required: true }) public set data(
    data: PartialPublicationTemplate<any>,
  ) {
    this._data = data;
    this.updatePreviewData();
  }
  protected readonly PublicationTemplateType = PublicationTemplateType;
  private readonly notFoundService = inject(NotFoundService);

  @ViewChild("previewContainer", { read: ViewContainerRef, static: true })
  protected readonly previewViewContainerRef!: ViewContainerRef;
  private previewRef?: ComponentRef<PublicationTemplatePreview<any>>;

  public ngOnInit(): void {
    const config = PUBLICATION_TEMPLATES.find(
      (config) => config.type === this._data.publicationTemplateType,
    );

    if (!config) {
      this.notFoundService.redirect("pt-config-not-found");
    }

    assert(config);
    this.previewRef = this.previewViewContainerRef.createComponent(
      config.preview,
    );
    this.updatePreviewData();
  }

  private updatePreviewData(): void {
    if (!this.previewRef) {
      return;
    }

    this.previewRef.instance.data = this._data;
  }
}
