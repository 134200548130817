<div class="preview">
  @if (media.mediaType === "image") {
    <img class="preview__media" src="{{ media.link }}" />
  } @else if (media.mediaType === "video") {
    @if (media.thumbnailLink) {
      <img class="preview__media" src="{{ media.thumbnailLink }}" />
    } @else {
      <video class="preview__media" src="{{ media.link }}"></video>
    }
  }

  @if (removable) {
    <button class="preview__close" (click)="remove.emit()">
      <mat-icon>close</mat-icon>
    </button>
  }
</div>
