import { CdkDrag, CdkDropList } from "@angular/cdk/drag-drop";
import {
  CommonModule as NgCommonModule,
  NgOptimizedImage,
} from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_NATIVE_DATE_FORMATS,
  MatNativeDateModule,
  NativeDateAdapter,
} from "@angular/material/core";
import { RouterModule, Routes } from "@angular/router";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FullCalendarModule } from "@fullcalendar/angular";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BrandCampaignProviderModule } from "../../../features/brand-campaign-partners/brand-campaign.provider.module";
import { AggregatedFilterComponent } from "../../../shared/components/filtering/aggregated-filter/aggregated-filter.component";
import { CommonDialogService } from "../../../shared/services/common-dialog.service";
import { getStandardLocaleSignal } from "../../../shared/services/language.service";
import { MultipleImageOrVideoUploaderComponent } from "./components/multiple-image-or-video-uploader/multiple-image-or-video-uploader.component";
import { PublicationTemplatePreviewComponent } from "./components/publication-template-preview/publication-template-preview.component";
import { PublicationTemplateSchedulesComponent } from "./components/publication-template-schedules/publication-template-schedules.component";
import { SchedulePublicationTemplateDialogComponent } from "./components/schedule-publication-template-dialog/schedule-publication-template-dialog.component";
import { PublicationTemplateProviderModule } from "./domain/publication-template.provider.module";
import { LoaderComponent } from "../../../shared/components/loader/loader.component";
import { FileInputComponent } from "../../../shared/components/file-input/file-input.component";
import { SingleImageOrVideoUploaderComponent } from "./components/single-image-or-video-uploader/single-image-or-video-uploader.component";
import { PageHeaderComponent } from "../../../shared/components/page-header/page-header.component";
import { SharedComponentsModule } from "../../../shared/components/shared-components.module";
import { MediaPreviewComponent } from "../../../shared/components/media-preview/media-preview.component";
import { AngularMaterialModule } from "../../../shared/material/material.module";
import { SharedPipesModule } from "../../../shared/pipes/shared-pipes.module";
import { CalendarDayCellComponent } from "./pages/publication-template-schedule/calendar-day-cell/calendar-day-cell.component";
import { CalendarEventPublicationComponent } from "./pages/publication-template-schedule/calendar-event-publication/calendar-event-publication.component";
import {
  FacebookCarouselCalendarEventFactory,
  InstagramStoryCalendarEventFactory,
  PublicationTemplateCalendarEventFactory,
} from "./pages/publication-template-schedule/calendar-event-publication/calendar-event.mappers";
import { PublicationTemplateScheduleComponent } from "./pages/publication-template-schedule/publication-template-schedule.component";
import { PublicationTemplateDialogService } from "./publication-template-dialog.service";
import { FacebookCarouselExtraSlideFormComponent } from "./publication-templates/facebook-carousel/facebook-carousel-form/facebook-carousel-extra-slide-form/facebook-carousel-extra-slide-form.component";
import { FacebookCarouselFormComponent } from "./publication-templates/facebook-carousel/facebook-carousel-form/facebook-carousel-form.component";
import { FacebookCarouselSlideFormComponent } from "./publication-templates/facebook-carousel/facebook-carousel-form/facebook-carousel-slide-form/facebook-carousel-slide-form.component";
import { FacebookCarouselPreviewComponent } from "./publication-templates/facebook-carousel/facebook-carousel-preview/facebook-carousel-preview.component";
import { FacebookCarouselRulesBoxComponent } from "./publication-templates/facebook-carousel/facebook-carousel-rules-box/facebook-carousel-rules-box.component";
import {
  FacebookCarouselFormToApiMapper,
  FacebookCarouselFormToPreviewMapper,
} from "./publication-templates/facebook-carousel/facebook-carousel.mapper";
import { InstagramStoryPreviewComponent } from "./publication-templates/instagram-story/instagram-story-preview/instagram-story-preview.component";
import { InstagramStoryFormComponent } from "./publication-templates/instagram-story/instagram-story-form/instagram-story-form.component";
import { InstagramStoryRulesBoxComponent } from "./publication-templates/instagram-story/instagram-story-rules-box/instagram-story-rules-box.component";
import { PublicationTemplateUpsertComponent } from "./pages/publication-template-upsert/publication-template-upsert.component";
import { PublicationTemplateThumbnailComponent } from "./components/publication-template-thumbnail/publication-template-thumbnail.component";
import { PublicationTemplateTypesComponent } from "./pages/publication-template-types/publication-template-types.component";
import { PublicationTemplateService } from "./data/publication-template.service";
import { PUBLICATION_TEMPLATES } from "./publication-templates/publication-templates.config";
import {
  InstagramStoryFormToApiMapper,
  InstagramStoryFormToPreviewMapper,
} from "./publication-templates/instagram-story/instagram-story.mapper";
import { ErrorMessageComponent } from "../../../shared/components/error-message/error-message.component";
import { ReloadComponent } from "../../../shared/components/reload/reload.component";

export const routes: Routes = [
  {
    path: "types",
    component: PublicationTemplateTypesComponent,
  },
  {
    path: ":publicationTemplateId/edit",
    component: PublicationTemplateUpsertComponent,
  },
  // Create
  ...PUBLICATION_TEMPLATES.map((config) => ({
    path: config.path,
    component: PublicationTemplateUpsertComponent,
    data: config,
  })),
  {
    path: ":publicationTemplateId/schedule",
    component: PublicationTemplateScheduleComponent,
  },
];

@NgModule({
  declarations: [
    InstagramStoryFormComponent,
    FacebookCarouselFormComponent,
    PublicationTemplatePreviewComponent,
    PublicationTemplateThumbnailComponent,
    PublicationTemplateTypesComponent,
    PublicationTemplateUpsertComponent,
    PublicationTemplateScheduleComponent,
    SingleImageOrVideoUploaderComponent,
    MultipleImageOrVideoUploaderComponent,
    SchedulePublicationTemplateDialogComponent,
  ],
  imports: [
    MatNativeDateModule,
    BrandCampaignProviderModule,
    RouterModule.forChild(routes),
    PublicationTemplateProviderModule,
    FullCalendarModule,
    TranslateModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgCommonModule,
    AngularMaterialModule,
    SharedComponentsModule,
    SharedPipesModule,
    ReactiveFormsModule,
    LoaderComponent,
    NgOptimizedImage,
    FileInputComponent,
    PageHeaderComponent,
    MediaPreviewComponent,
    InstagramStoryPreviewComponent,
    InstagramStoryRulesBoxComponent,
    FacebookCarouselPreviewComponent,
    FacebookCarouselRulesBoxComponent,
    ErrorMessageComponent,
    PublicationTemplateSchedulesComponent,
    CalendarDayCellComponent,
    CalendarEventPublicationComponent,
    FacebookCarouselSlideFormComponent,
    CKEditorModule,
    ReloadComponent,
    AggregatedFilterComponent,
    FacebookCarouselExtraSlideFormComponent,
    CdkDropList,
    CdkDrag,
  ],
  providers: [
    PublicationTemplateService,
    PublicationTemplateDialogService,
    InstagramStoryCalendarEventFactory,
    PublicationTemplateCalendarEventFactory,
    FacebookCarouselCalendarEventFactory,
    CommonDialogService,
    {
      provide: InstagramStoryFormToApiMapper,
      useClass: InstagramStoryFormToApiMapper,
    },
    {
      provide: InstagramStoryFormToPreviewMapper,
      useClass: InstagramStoryFormToPreviewMapper,
    },
    {
      provide: FacebookCarouselFormToApiMapper,
      useClass: FacebookCarouselFormToApiMapper,
    },
    {
      provide: FacebookCarouselFormToPreviewMapper,
      useClass: FacebookCarouselFormToPreviewMapper,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: getStandardLocaleSignal(),
    },
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
  ],
  exports: [PublicationTemplatePreviewComponent],
})
export class PublicationTemplateModule {}
