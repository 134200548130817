<form [formGroup]="form">
  <h3 class="section-title">{{ "shared.media" | translate }}</h3>
  <app-multiple-image-or-video-uploader
    [type]="type"
    [control]="form.controls.media"
    [form]="form"
    [fields]="fields"
    [maxNumFiles]="maxNumSlides"
    [availableNumFiles]="maxNumSlides - form.controls.slides.length"
  />
  @if (
    form.touched &&
    (form.controls.slides.errors?.minElements ||
      form.controls.slides.errors?.maxElements)
  ) {
    <app-error-message key="The number of slides must be between 2 and 10" />
  }
  <div class="slides">
    @if (form) {
      <div
        class="sortable-list"
        cdkDropList
        (cdkDropListDropped)="onActionSlidesReordered($event)"
      >
        @for (
          slide of form.controls.slides.controls;
          track slide.value.media!.id;
          let i = $index
        ) {
          <app-facebook-carousel-slide-form
            class="draggable-item"
            cdkDrag
            [media]="slide.value.media!"
            [slideForm]="slide"
            [fields]="fields"
            (remove)="removeSlide(i)"
          />
        }
      </div>
      <app-facebook-carousel-extra-slide-form [form]="form" [fields]="fields" />
    }
  </div>
  <h3 class="section-title">{{ "shared.text" | translate }}</h3>
  <mat-form-field floatLabel="always">
    <mat-label>Your primary content</mat-label>
    <textarea
      formControlName="text"
      matInput
      rows="5"
      (focus)="isTextFocused = true"
      (blur)="isTextFocused = false"
    ></textarea>
    @if (isTextFocused && maxTextLength) {
      <mat-hint align="end">
        {{ form.controls.text.value?.length ?? 0 }} Characters ({{
          maxTextLength
        }}
        recommended)
      </mat-hint>
    }
    @if (form.touched && form.hasError("text", "required")) {
      <mat-error>
        {{ "shared.requiredField" | translate }}
      </mat-error>
    }
    @if (form.touched && form.hasError("text", "maxlength")) {
      <mat-error>{{
        "The maximum length of this text is " + maxTextLength + " characters"
          | translate
      }}</mat-error>
    }
  </mat-form-field>
</form>
