import { PublicationTemplateService } from "../../data/publication-template.service";
import { PublicationTemplate } from "../models/publication-template";

export class GetPublicationTemplateInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
  ) {}

  public async execute(id: string): Promise<PublicationTemplate> {
    return this.publicationTemplateService.get(id);
  }
}
