<div class="section-container">
  <div class="media">
    <app-file-input
      [multiple]="true"
      [accept]="getAllowedMimeTypes()"
      [isDisabled]="isLoading || control.value.length > 0"
      (selected)="onUploadFiles($event)"
    />
  </div>
  <div class="errors">
    @if (currentError) {
      <app-error-message [error]="currentError" prefix="media.error." />
    } @else if (form.touched && control.errors?.required) {
      <app-error-message key="publicationTemplate.update.error.required" />
    }
  </div>
</div>
