<table class="list">
  <tbody>
    @for (item of rules; track item.label) {
      <tr>
        <td class="list__label">
          {{ "publicationTemplate.rules." + item.label | translate }}
        </td>
        <td class="list__description">
          {{
            "publicationTemplate.rules." + item.description
              | translate: item.descriptionParams ?? {}
          }}
        </td>
      </tr>
    }
  </tbody>
</table>
