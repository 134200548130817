<mat-icon>{{ FilterIcon[filter().type] }}</mat-icon>
@if (filter().type === FilterType.TimePeriod) {
  @if (filter().value.name === "start") {
    {{ "filter.dateFrom" | translate }}
    {{ filter().value.id | date: "MMMM d YYYY" }}
  }

  @if (filter().value.name === "end") {
    {{ "filter.dateTo" | translate }}
    {{ filter().value.id | date: "MMMM d YYYY" }}
  }
} @else {
  {{ filter().value.name | translate }}
}
<button (click)="remove.emit()">
  <mat-icon>close</mat-icon>
</button>
