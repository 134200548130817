import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  style,
} from "@angular/animations";
import { NgClass, NgForOf } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  QueryList,
  Renderer2,
  signal,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { ImageMedia } from "../../../../../../features/media/domain/models/media";
import { PublicationTemplatePreview } from "../../../components/publication-template-preview/publication-template-preview";
import { PublicationTemplateType } from "../../../data/entities/publication-templates.entity";
import {
  FacebookCarouselSlide,
  LinkType,
} from "../../../domain/models/publication-template";
import { FacebookCarouselSlidePreviewComponent } from "./facebook-carousel-slide-preview/facebook-carousel-slide-preview.component";

@Component({
  standalone: true,
  selector: "app-facebook-carousel-preview",
  templateUrl: "./facebook-carousel-preview.component.html",
  styleUrls: ["./facebook-carousel-preview.component.scss"],
  imports: [
    TranslateModule,
    MatIcon,
    NgForOf,
    NgClass,
    FacebookCarouselSlidePreviewComponent,
  ],
})
export class FacebookCarouselPreviewComponent
  extends PublicationTemplatePreview<PublicationTemplateType.FacebookCarousel>
  implements AfterViewInit
{
  @ViewChild("carousel") private carousel!: ElementRef;
  @ViewChild("carouselWrapper") private carouselWrapper!: ElementRef;
  @ViewChildren("slide", { read: ElementRef })
  private slides?: QueryList<ElementRef>;
  private readonly sampleImage = "/assets/images/company.png";

  protected readonly currentSlide = signal(0);
  protected readonly extraSlide = new FacebookCarouselSlide(
    new ImageMedia("1", 300, 300, this.sampleImage, this.sampleImage),
    "publicationTemplate.preview.facebookCarousel.seeMoreAtPublisher",
    LinkType.CustomUrl,
    "https://socialpals.de",
  );

  constructor(
    private readonly builder: AnimationBuilder,
    private readonly renderer: Renderer2,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    if (this.slides?.length) {
      this.renderer.addClass(this.slides.first.nativeElement, "current");
    }
  }

  private createAnimationPlayer(
    el: HTMLElement,
    offset: number,
  ): AnimationPlayer {
    return this.builder
      .build([
        animate(
          "300ms ease-in-out",
          style({ transform: `translateX(-${offset}px)` }),
        ),
      ])
      .create(el);
  }

  protected next(): void {
    if (this.currentSlide() + 1 === this.slides?.length) {
      return;
    }

    this.goToSlide(this.currentSlide() + 1);
  }

  protected prev(): void {
    if (this.currentSlide() === 0) {
      return;
    }

    this.goToSlide(this.currentSlide() - 1);
  }

  public goToSlide(index: number): void {
    this.currentSlide.set(index);
    this.animate();
  }

  private animate(): void {
    if (!this.slides) {
      return;
    }

    const carouselPadding = 16;
    const carouselGap = 24;
    const wBorder = 1;

    const wItem = this.slides.first.nativeElement.getBoundingClientRect().width;
    const wWrapper =
      this.carouselWrapper.nativeElement.getBoundingClientRect().width;

    const wFirstSlide = wItem + carouselPadding;
    const wSlide = wItem + carouselGap;
    const freeSpace = wWrapper - wItem - carouselGap * 2;

    const offset =
      this.currentSlide() === 0
        ? 0
        : this.currentSlide() + 1 === this.slides.length
          ? wFirstSlide +
            wSlide * this.currentSlide() -
            wWrapper +
            carouselPadding
          : wFirstSlide +
            wSlide * (this.currentSlide() - 1) -
            freeSpace / 2 +
            wBorder;

    const player = this.createAnimationPlayer(
      this.carousel.nativeElement,
      Math.abs(offset),
    );

    player.play();

    this.slides.forEach((item, index) => {
      if (this.currentSlide() !== index) {
        this.renderer.removeClass(item.nativeElement, "current");
      } else {
        this.renderer.addClass(item.nativeElement, "current");
      }
    });
  }
}
