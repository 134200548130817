<app-page-header
  [title]="
    publicationTemplate
      ? 'publicationTemplate.schedule.' +
        (publicationTemplate.publicationTemplateType
          | caseTransform: 'SnakeToCamel') +
        '.title'
      : ''
  "
>
  <a
    class="btn close-btn"
    [routerLink]="BrandUrl.Campaign.Publications(brandId, campaignId)"
  >
    {{ "publicationTemplate.schedule.toContent" | translate }}
  </a>
</app-page-header>
<div class="main">
  <div class="preview-section" #previewWrapper>
    <div class="preview">
      @if (publicationTemplate) {
        <div class="post-header">
          <div class="section">
            <img
              [src]="
                '/assets/images/icons/ic_' +
                publicationTemplate.platform +
                'Content.svg'
              "
              class="header-icon"
              alt="instagram-icon"
            />
            <h3 class="post-type">
              {{
                "publicationTemplatePostContainer." +
                  (publicationTemplate.publicationTemplateType
                    | caseTransform: "SnakeToCamel") | translate
              }}
            </h3>
          </div>
          <div class="section">
            <a
              class="edit-link"
              [routerLink]="
                BrandUrl.Campaign.PublicationTemplate(brandId, campaignId).Edit(
                  publicationTemplate.id
                )
              "
            >
              {{ "shared.details" | translate }}
            </a>
          </div>
        </div>
        <div class="preview-container">
          <app-publication-template-preview [data]="publicationTemplate" />
        </div>
        <app-publication-template-schedules
          [campaign]="campaign"
          [publicationTemplateId]="publicationTemplate.id"
          [schedules]="publicationTemplate.recommendedSchedules"
          [mode]="'edit'"
          (schedulesUpdated)="onSchedulesUpdated($event)"
          (goToScheduleWithEdition)="onEditSchedule()"
        />
      }
    </div>
  </div>
  <div class="calendar-wrapper" #calendarWrapper>
    @if (calendarConfig) {
      <div class="calendar-info">
        <mat-icon>info_outline</mat-icon>
        <span>
          {{ "publicationTemplate.schedule.select" | translate }}
        </span>
      </div>
      <full-calendar #calendar [options]="calendarConfig">
        <ng-template #dayCellContent let-arg>
          <app-calendar-day-cell
            [dayNumberText]="arg.dayNumberText"
            [date]="arg.date"
            (add)="onSchedulePublication($event)"
          />
        </ng-template>
        <ng-template #eventContent let-arg>
          <app-calendar-event-publication
            [event]="arg.event"
            [class.disabled]="arg.event.extendedProps.disabled"
          />
        </ng-template>
      </full-calendar>
    }
  </div>
</div>
