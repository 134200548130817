<div class="container">
  @if (!disabled) {
    <a
      [routerLink]="[formRoute]"
      class="thumbnail-container thumbnail-container--{{
        platform | lowercase
      }}"
    >
      <div class="hover-layer">
        <div class="hover-message">
          <span>{{
            "publicationTemplate.thumbnail." +
              (type | caseTransform: "SnakeToCamel") +
              ".create" | translate
          }}</span>
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </div>
      <img
        class="thumbnail"
        [alt]="type"
        [src]="
          '/assets/images/publication-template/thumbnail/as_' +
          (type | lowercase) +
          '.png'
        "
      />
    </a>
  } @else {
    <div
      class="thumbnail-container thumbnail-container--{{
        platform | lowercase
      }}"
    >
      <img
        class="thumbnail"
        [alt]="type"
        [src]="
          '/assets/images/publication-template/thumbnail/as_' +
          (type | lowercase) +
          '.png'
        "
      />
    </div>
  }
  <div class="thumbnail-name">
    {{
      "publicationTemplate.thumbnail." +
        (type | caseTransform: "SnakeToCamel") +
        ".name" | translate
    }}
    <mat-icon
      class="icon"
      matTooltip="{{
        'publicationTemplate.thumbnail.' +
          (type | caseTransform: 'SnakeToCamel') +
          '.description' | translate
      }}"
    >
      info_outlined
    </mat-icon>
  </div>
</div>
