import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { environment } from "../../../environments/environment";
import { versions } from "../../../environments/version";
import {
  LocalStorageService,
  StorageKeys,
  StorageUserItem,
} from "./local-storage.service";
import { SessionStorageService } from "./session-storage.service";

@Injectable({ providedIn: "root" })
export class SentryService {
  public readonly BUNDLE_ERRORS = [
    /Loading chunk/i,
    /Failed to fetch dynamically imported module/i,
    /Error loading dynamically imported module/i,
    /Importing a module script failed/i,
  ];
  public readonly STORAGE_ERRORS = [
    /The operation is insecure/i,
    /Failed to read the 'localStorage'/i,
  ];

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly sessionStorageService: SessionStorageService,
  ) {
    if (environment.sentryDSN) {
      Sentry.init({
        dsn: environment.sentryDSN,
        release: versions.commit,
        ignoreErrors: [
          /^Non-Error exception captured/,
          ...this.BUNDLE_ERRORS,
          ...this.STORAGE_ERRORS,
        ],
      });
    }
  }

  public captureException(exception: unknown): void {
    const { CurrentPartnerId, CurrentBrandId, User } = StorageKeys;
    const user = this.localStorageService.getParsed<StorageUserItem>(User);

    Sentry.captureException(exception, {
      user: {
        id: user?.userId ? String(user.userId) : undefined,
        email: user?.email,
      },
      tags: {
        partnerId: this.localStorageService.get(CurrentPartnerId) ?? -1,
        partnerIdSession:
          this.sessionStorageService.get(CurrentPartnerId) ?? -1,
        brandId: this.localStorageService.get(CurrentBrandId) ?? -1,
        brandIdSession: this.sessionStorageService.get(CurrentBrandId) ?? -1,
        role: user?.userRole ?? "",
      },
    });
  }
}
