import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Media } from "../../../../../../features/media/domain/models/media";
import { PublicationTemplateForm } from "../../../components/publication-template-form/publication-template-form";
import { PublicationTemplateType } from "../../../data/entities/publication-templates.entity";
import { InstagramStory } from "../../../domain/models/publication-template";

export interface InstagramStoryForm {
  media: FormControl<Media | undefined>;
}

@Component({
  selector: "app-instagram-story-form",
  templateUrl: "./instagram-story-form.component.html",
  styleUrl:
    "../../../components/publication-template-form/publication-template-form.scss",
})
export class InstagramStoryFormComponent extends PublicationTemplateForm<InstagramStoryForm> {
  constructor() {
    super(PublicationTemplateType.InstagramStory);
  }

  public createForm(): void {
    this.form = new FormGroup({
      media: new FormControl<Media | undefined>(undefined, {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  public setFormValues(instagramStory: InstagramStory): void {
    this.form.patchValue({
      media: instagramStory.media,
    });
  }
}
