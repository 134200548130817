import { NgStyle } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { Media } from "../../../features/media/domain/models/media";

@Component({
  standalone: true,
  selector: "app-media-preview",
  templateUrl: "./media-preview.component.html",
  styleUrls: ["./media-preview.component.scss"],
  imports: [MatIconModule, NgStyle],
})
export class MediaPreviewComponent {
  @Output() public remove = new EventEmitter<void>();
  @Input() public removable = true;
  @Input({ required: true }) public media!: Media;
}
