import { NgClass } from "@angular/common";
import {
  Component,
  computed,
  EventEmitter,
  input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from "@angular/material/autocomplete";
import {
  MatFormField,
  MatLabel,
  MatPrefix,
} from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { from, Subscription } from "rxjs";
import { debounceTime, switchMap, tap } from "rxjs/operators";
import { GetSelectorOptionsInteractor } from "../../../../features/selector-options/selector-options.providers";
import { SharedComponentsModule } from "../../shared-components.module";
import {
  FilterIcon,
  FilterOutput,
  FilterType,
  isSearchableFilter,
  SelectorOption,
} from "../filter.interfaces";

@Component({
  standalone: true,
  selector: "app-autocomplete-selector",
  templateUrl: "./autocomplete-selector.component.html",
  styleUrl: "./autocomplete-selector.component.scss",
  imports: [
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    MatProgressSpinner,
    MatLabel,
    MatFormField,
    MatInput,
    SharedComponentsModule,
    MatIcon,
    NgClass,
    MatPrefix,
    ReactiveFormsModule,
  ],
})
export class AutocompleteSelectorComponent implements OnInit, OnDestroy {
  @Output() public optionSelected = new EventEmitter<FilterOutput[]>();
  public readonly interactor = input.required<GetSelectorOptionsInteractor>();
  public readonly type = input.required<FilterType>();
  public readonly campaignId = input<number | undefined>();

  protected options = signal<SelectorOption[]>([]);
  protected areOptionsShown = signal(false);
  protected isLoading = signal<boolean>(false);
  protected areAllOptionsLoaded = signal(false);
  protected canSearch = computed(() => isSearchableFilter(this.type()));
  protected readonly searchControl = new FormControl<string>("");
  protected readonly FilterIcon = FilterIcon;
  private readonly pageSize = 10;
  private pageIndex = 1;
  private searchKey?: string;
  private readonly subs = new Subscription();

  public async ngOnInit(): Promise<void> {
    this.subs.add(
      this.searchControl.valueChanges
        .pipe(
          tap((key) => {
            this.initSearch(key ?? undefined);
          }),
          debounceTime(300),
          switchMap((key) =>
            from(
              this.interactor().execute(
                this.pageIndex,
                this.pageSize,
                key,
                this.campaignId(),
              ),
            ),
          ),
        )
        .subscribe({
          next: (results) => {
            this.options.set(results.entries);
            this.areAllOptionsLoaded.set(
              this.options().length >= results.totalSize,
            );
          },
          error: () => this.options.set([]),
          complete: () => {
            this.isLoading.set(false);
          },
        }),
    );

    await this.loadOptions();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initSearch(key?: string): void {
    this.searchKey = key;
    this.pageIndex = 1;
    this.options.set([]);
    this.areAllOptionsLoaded.set(false);
    this.isLoading.set(true);
  }

  private async loadOptions(): Promise<void> {
    this.isLoading.set(true);
    const newOptions = await this.interactor().execute(
      this.pageIndex,
      this.pageSize,
      this.searchKey,
      this.campaignId(),
    );

    this.options.update((options) => [...options, ...newOptions.entries]);
    this.areAllOptionsLoaded.set(this.options().length >= newOptions.totalSize);
    this.isLoading.set(false);
  }

  protected onActionOptionSelected(option: SelectorOption): void {
    this.optionSelected.emit([
      {
        type: this.type(),
        value: option,
      },
    ]);
  }

  protected async onOptionsScrolled(): Promise<void> {
    this.pageIndex++;
    await this.loadOptions();
  }
}
