<span class="close-icon" [mat-dialog-close]="">
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title>
  {{ "publicationTemplate.schedule.dialog.title" | translate }}
</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    @if (campaign) {
      <span class="label">Date</span>
      <mat-form-field class="date-selector">
        <mat-label>{{ "shared.date" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [min]="minSelectableDate"
          formControlName="startDate"
        />
        <mat-datepicker-toggle matIconSuffix [for]="picker" />
        <mat-datepicker #picker></mat-datepicker>
        @if (form.controls.startDate.errors?.matDatepickerMin) {
          <mat-error>
            {{ "publicationTemplate.schedule.error.minDate" | translate }}
          </mat-error>
        }
        @if (form.controls.startDate.errors?.matDatepickerMax) {
          <mat-error>
            {{ "publicationTemplate.schedule.error.maxDate" | translate }}
          </mat-error>
        }
        @if (form.controls.startDate.errors?.timeSpace) {
          <mat-error>
            {{ "publicationTemplate.schedule.error.timeSpace" | translate }}
          </mat-error>
        }
      </mat-form-field>

      <span class="label">
        <span>Time</span>
        @if (campaignTime) {
          @if (isStartingDay) {
            <p class="label-hint">Campaign starts at: {{ campaignTime }}</p>
          }

          @if (isEndingDay) {
            <p class="label-hint">Campaign ends at: {{ campaignTime }}</p>
          }
        }
      </span>
      <div class="time-selection">
        <mat-form-field class="time-selector" subscriptSizing="dynamic">
          <mat-label>{{ "shared.hours" | translate }}</mat-label>
          <mat-select formControlName="startHours">
            @for (hour of hours; track hour) {
              <mat-option [value]="+hour">{{ hour }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field class="time-selector" subscriptSizing="dynamic">
          <mat-label>{{ "shared.minutes" | translate }}</mat-label>
          <mat-select formControlName="startMinutes">
            @for (minute of minutes; track minute) {
              <mat-option [value]="+minute">{{ minute }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="timezone-selection">
        @if (userTimezone) {
          <span>
            (GMT{{ userTimezone.offset | offsetToTimezone }}) -
            {{ TIMEZONES_BY_OFFSET[userTimezone.offset].name }}
          </span>
        }
      </div>
    }
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div>
    @if (scheduleId && !isEventDragged()) {
      <button
        class="btn btn--content-only btn--delete"
        (click)="deleteSchedule()"
      >
        {{ "shared.delete" | translate }}
      </button>
    }
  </div>
  <div>
    <button
      class="btn btn--transparent"
      [mat-dialog-close]=""
      [disabled]="loading()"
    >
      <span>{{ "shared.cancel" | translate }}</span>
    </button>
    <button class="btn btn--primary" (click)="save()" [disabled]="loading()">
      @if (loading()) {
        <span>{{ "shared.saving" | translate }}</span>
      } @else {
        <span>{{ "shared.save" | translate }}</span>
      }
    </button>
  </div>
</mat-dialog-actions>
