import { z } from "zod";
import { MediaEntity } from "../../../../../features/media/data/entities/media.entity";
import { LinkType } from "../../domain/models/publication-template";

export enum PublicationTemplateType {
  FacebookCarousel = "FACEBOOK_CAROUSEL",
  InstagramStory = "INSTAGRAM_STORY",
}

export const RecommendedScheduleParser = z
  .object({
    id: z.string(),
    publication_template_id: z.string(),
    start: z.number(),
    end: z.number().nullable(),
  })
  .strict();

export type RecommendedScheduleEntity = Readonly<
  z.infer<typeof RecommendedScheduleParser>
>;

export interface IPublicationTemplateEntity {
  readonly id: string;
  readonly publicationTemplateType: PublicationTemplateType;
  readonly recommendedSchedules?: RecommendedScheduleEntity[];
}

export class InstagramStoryEntity implements IPublicationTemplateEntity {
  public readonly publicationTemplateType =
    PublicationTemplateType.InstagramStory;

  constructor(
    public readonly id: string,
    public readonly media: MediaEntity,
    public readonly recommendedSchedules?: RecommendedScheduleEntity[],
  ) {}
}

// todo: move fb carousel to specific file
export class FacebookCarouselSlideEntity {
  constructor(
    public readonly media: MediaEntity,
    public readonly title: string,
    public readonly landingPageType: LinkType,
    public readonly landingPageUrl: string,
    public readonly description?: string,
  ) {}
}

export class FacebookCarouselEntity implements IPublicationTemplateEntity {
  public readonly publicationTemplateType =
    PublicationTemplateType.FacebookCarousel;

  constructor(
    public readonly id: string,
    public readonly slides: FacebookCarouselSlideEntity[],
    public readonly text: string,
    public readonly landingPageType: LinkType,
    public readonly recommendedSchedules?: RecommendedScheduleEntity[],
    public readonly landingPageUrl?: string,
  ) {}
}

export type PublicationTemplateEntity =
  | InstagramStoryEntity
  | FacebookCarouselEntity;
