<mat-icon class="drag-indicator">drag_indicator</mat-icon>
<app-media-preview
  class="media-preview"
  [media]="slideForm.controls.media.value"
  [removable]="false"
/>
<form class="text-fields" [formGroup]="slideForm">
  <app-publication-template-link-selector
    [form]="slideForm"
    [maxLength]="maxLinkLength()"
  />
  <mat-form-field class="field">
    <mat-label>Headline</mat-label>
    <input
      matInput
      formControlName="title"
      (focus)="inputFocused.set('title')"
      (blur)="inputFocused.set(undefined)"
    />
    @if (inputFocused() === "title") {
      <mat-hint align="end">
        {{ slideForm.controls.title.value?.length ?? 0 }} Characters ({{
          maxTitleLength()
        }}
        recommended)
      </mat-hint>
    }
    @if (slideForm.touched && slideForm.controls.title.errors?.required) {
      <mat-error>{{ "shared.requiredField" | translate }}</mat-error>
    }
    @if (slideForm.touched && slideForm.controls.title.errors?.maxlength) {
      <mat-error>
        {{ "Max length: " + maxTitleLength() + " characters" | translate }}
      </mat-error>
    }
  </mat-form-field>
  <mat-form-field class="field">
    <mat-label>Description</mat-label>
    <input
      matInput
      formControlName="description"
      (focus)="inputFocused.set('description')"
      (blur)="inputFocused.set(undefined)"
    />
    @if (inputFocused() === "description") {
      <mat-hint align="end">
        {{ slideForm.controls.description.value?.length ?? 0 }} Characters ({{
          maxDescriptionLength()
        }}
        recommended)
      </mat-hint>
    }
    @if (
      slideForm.touched && slideForm.controls.description.errors?.maxlength
    ) {
      <mat-error>
        {{
          "Max length: " + maxDescriptionLength() + " characters" | translate
        }}
      </mat-error>
    }
  </mat-form-field>
</form>

<button class="remove-button" (click)="remove.emit()">
  <mat-icon>close</mat-icon>
</button>
