<div class="event-publication" [style.border-color]="event.borderColor">
  <img class="thumbnail" [src]="event.extendedProps.image" alt="thumbnail" />
  <span class="title">{{ event.extendedProps.title | translate }}</span>
  <section class="right">
    <img
      class="network-icon"
      [src]="
        'assets/images/icons/ic_' +
        (event.extendedProps.platform | lowercase) +
        '.svg'
      "
      alt="network-icon"
    />
  </section>
</div>
