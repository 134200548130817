import { ImageMedia, Media, VideoMedia } from "../models/media";

export const mapFileToMedia = (from: File, id = ""): Media => {
  let objectUrl: string;

  try {
    objectUrl = URL.createObjectURL(from);
  } catch (_) {
    throw new Error("Unknown media type.");
  }

  if (from.type.startsWith("image")) {
    return new ImageMedia(id, 0, 0, objectUrl, "", "", false);
  } else if (from.type.startsWith("video")) {
    return new VideoMedia(id, 0, 0, objectUrl, "", 0, 0, 0, "", 0, 0, 0, "");
  }

  throw new Error("Unknown media type.");
};
