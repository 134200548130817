import { Cached } from "../../../../../harmony/core";
import { NotificationService } from "../../../../shared/services/notification.service";
import { PublicationTemplateService } from "../data/publication-template.service";
import { CreatePublicationTemplateInteractor } from "./interactors/create-publication-template.interactor";
import { CreateRecommendedScheduleInteractor } from "./interactors/create-recommended-schedule.interactor";
import { DeletePublicationTemplateInteractor } from "./interactors/delete-publication-template.interactor";
import { DeleteRecommendedScheduleInteractor } from "./interactors/delete-recommended-schedule.interactor";
import { GetPublicationTemplateFieldsInteractor } from "./interactors/get-publication-template-fields.interactor";
import { GetPublicationTemplateTypesByCampaignInteractor } from "./interactors/get-publication-template-types-by-campaign.interactor";
import { GetPublicationTemplateInteractor } from "./interactors/get-publication-template.interactor";
import { GetPublicationTemplatesInteractor } from "./interactors/get-publication-templates.interactor";
import { UpdatePublicationTemplateInteractor } from "./interactors/update-publication-template.interactor";
import { UpdateRecommendedScheduleInteractor } from "./interactors/update-recommended-schedule.interactor";

export abstract class PublicationTemplateProvider {
  abstract provideCreatePublicationTemplate(): CreatePublicationTemplateInteractor;
  abstract provideUpdatePublicationTemplate(): UpdatePublicationTemplateInteractor;
  abstract provideDeletePublicationTemplate(): DeletePublicationTemplateInteractor;
  abstract provideGetPublicationTemplate(): GetPublicationTemplateInteractor;
  abstract provideGetPublicationTemplateFields(): GetPublicationTemplateFieldsInteractor;
  abstract provideGetPublicationTemplates(): GetPublicationTemplatesInteractor;
  abstract provideGetPublicationTemplateTypesByCampaign(): GetPublicationTemplateTypesByCampaignInteractor;
  abstract provideCreateRecommendedSchedule(): CreateRecommendedScheduleInteractor;
  abstract provideUpdateRecommendedSchedule(): UpdateRecommendedScheduleInteractor;
  abstract provideDeleteRecommendedSchedule(): DeleteRecommendedScheduleInteractor;
}

export class PublicationTemplatesDefaultProvider
  implements PublicationTemplateProvider
{
  constructor(
    private readonly publicationTemplatesService: PublicationTemplateService,
    private readonly notificationService: NotificationService,
  ) {}

  @Cached()
  public provideCreatePublicationTemplate(): CreatePublicationTemplateInteractor {
    return new CreatePublicationTemplateInteractor(
      this.publicationTemplatesService,
      this.notificationService,
    );
  }

  @Cached()
  public provideUpdatePublicationTemplate(): UpdatePublicationTemplateInteractor {
    return new UpdatePublicationTemplateInteractor(
      this.publicationTemplatesService,
      this.notificationService,
    );
  }

  @Cached()
  public provideDeletePublicationTemplate(): DeletePublicationTemplateInteractor {
    return new DeletePublicationTemplateInteractor(
      this.publicationTemplatesService,
    );
  }

  @Cached()
  public provideGetPublicationTemplate(): GetPublicationTemplateInteractor {
    return new GetPublicationTemplateInteractor(
      this.publicationTemplatesService,
    );
  }

  @Cached()
  public provideGetPublicationTemplateFields(): GetPublicationTemplateFieldsInteractor {
    return new GetPublicationTemplateFieldsInteractor(
      this.publicationTemplatesService,
    );
  }

  @Cached()
  public provideGetPublicationTemplates(): GetPublicationTemplatesInteractor {
    return new GetPublicationTemplatesInteractor(
      this.publicationTemplatesService,
    );
  }

  @Cached()
  public provideGetPublicationTemplateTypesByCampaign(): GetPublicationTemplateTypesByCampaignInteractor {
    return new GetPublicationTemplateTypesByCampaignInteractor(
      this.publicationTemplatesService,
    );
  }

  @Cached()
  public provideCreateRecommendedSchedule(): CreateRecommendedScheduleInteractor {
    return new CreateRecommendedScheduleInteractor(
      this.publicationTemplatesService,
    );
  }

  @Cached()
  public provideUpdateRecommendedSchedule(): UpdateRecommendedScheduleInteractor {
    return new UpdateRecommendedScheduleInteractor(
      this.publicationTemplatesService,
    );
  }

  @Cached()
  public provideDeleteRecommendedSchedule(): DeleteRecommendedScheduleInteractor {
    return new DeleteRecommendedScheduleInteractor(
      this.publicationTemplatesService,
    );
  }
}
