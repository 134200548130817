import { assert } from "../../../../../shared/utils/assert";
import {
  ImageRules,
  Rules,
  TextRules,
  VideoRules,
} from "./publication-template-rules.entity";

export interface Field<Name extends string, R extends Rules> {
  name: Name;
  rules: R;
}

export type InstagramStoryFields = [
  Field<"image", ImageRules>,
  Field<"video", VideoRules>,
];

// todo: maybe this is not necessary and we can just have a PTField
export type FacebookCarouselFields = [
  Field<"slides", TextRules>,
  Field<"mediaTitle", TextRules>,
  Field<"mediaDescription", TextRules>,
  Field<"landingPageUrl", TextRules>,
  Field<"text", TextRules>,
  Field<"image", ImageRules>,
  Field<"video", VideoRules>,
];

export type PublicationTemplateFields =
  | InstagramStoryFields
  | FacebookCarouselFields;

export function getField<Name extends string>(
  name: Name,
  fields: PublicationTemplateFields,
): Field<Name, Rules> {
  const field = fields.find((rule) => rule.name === name);
  assert(field, `${name} field not found`);
  return field as Field<Name, Rules>;
}

export function getImageField<Name extends string>(
  name: Name,
  fields: PublicationTemplateFields,
): Field<Name, ImageRules> {
  const field = fields.find((rule) => rule.name === name);

  assert(field, `${name} field not found`);
  // assertImageRules(field.rules);

  return field as Field<Name, ImageRules>;
}

export function getVideoField<Name extends string>(
  name: Name,
  fields: PublicationTemplateFields,
): Field<Name, VideoRules> {
  const field = fields.find((rule) => rule.name === name);

  assert(field, `${name} field not found`);
  // assertVideoRules(field.rules);

  return field as Field<Name, VideoRules>;
}
