import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { PublicationTemplatePreview } from "../../../components/publication-template-preview/publication-template-preview";
import { PublicationTemplateType } from "../../../data/entities/publication-templates.entity";

@Component({
  standalone: true,
  selector: "app-instagram-story-preview",
  templateUrl: "./instagram-story-preview.component.html",
  styleUrls: ["./instagram-story-preview.component.scss"],
  imports: [TranslateModule],
})
export class InstagramStoryPreviewComponent extends PublicationTemplatePreview<PublicationTemplateType.InstagramStory> {}
