import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

export class RuleItemVM {
  constructor(
    public readonly label: string,
    public readonly description: string,
    public readonly descriptionParams?: Record<string, string | number>,
  ) {}
}

export type RuleList = RuleItemVM[];

@Component({
  standalone: true,
  selector: "app-rules-list",
  templateUrl: "./rules-list.component.html",
  styleUrl: "./rules-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class RulesListComponent {
  @Input({ required: true }) public rules!: RuleList;
}
