<div class="heading">
  <span>{{ "shared.schedules" | translate }}</span>
  @if (mode === "show") {
    <a class="details-link" (click)="goToScheduleWithEdition.emit(false)">
      Details
    </a>
  }
</div>

<div class="schedules">
  @for (schedule of sortedSchedules; track schedule.id) {
    <div class="schedule">
      <div class="dates">
        <span class="date">
          {{ schedule.startDate | date: "longDate" }}
        </span>
        <span class="time">
          {{ schedule.startDate | date: "HH:mm (ZZZZ)" }}
        </span>
      </div>
      @if (mode === "edit") {
        <a
          class="edit-button btn btn--content-only"
          (click)="onEditSchedule(schedule)"
        >
          <mat-icon>edit</mat-icon>
        </a>
      }
    </div>
  } @empty {
    <div class="no-schedules">
      {{ "publicationTemplate.schedule.noSchedules" | translate }}
    </div>
  }
</div>

@if (campaign) {
  <div class="actions">
    <button
      class="add-button btn btn--border-only"
      (click)="goToScheduleWithEdition.emit(true)"
    >
      <mat-icon>add</mat-icon> Add Schedule
    </button>
  </div>
}
