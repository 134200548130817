import { PublicationTemplateType } from "../../../brand/brand-campaign/publication-template/data/entities/publication-templates.entity";
import { PublicationTemplateService } from "../../../brand/brand-campaign/publication-template/data/publication-template.service";
import { SelectorOption } from "../../../shared/components/filtering/filter.interfaces";
import { Page } from "../../shared/domain/models/page";

export class GetPublicationTemplateTypeOptionsInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
  ) {}
  public async execute(): Promise<Page<SelectorOption>> {
    const availableTypes =
      this.publicationTemplateService.getAvailablePublicationTypes();
    const types = Object.values(PublicationTemplateType).filter((type) =>
      availableTypes.includes(type),
    );
    return {
      pageNumber: 0,
      size: types.length,
      totalSize: types.length,
      entries: types.map((type) => ({
        id: type,
        name: `publicationTemplate.name.${type}`,
      })),
    };
  }
}
