import { PublicationTemplateService } from "../../data/publication-template.service";

export class DeletePublicationTemplateInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
  ) {}

  public async execute(id: string): Promise<void> {
    return this.publicationTemplateService.delete(id);
  }
}
