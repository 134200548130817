import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Media } from "../../../../../features/media/domain/models/media";
import { PublicationTemplateFields } from "../../data/entities/publication-template-fields.entity";
import { Rules } from "../../data/entities/publication-template-rules.entity";
import { UploadPublicationTemplateMediaInteractor } from "../../domain/interactors/upload-publication-template-media.interactor";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";
import { PublicationTemplateService } from "../../data/publication-template.service";
import { ValidateFileClientSideInteractor } from "../../domain/interactors/validate-file-client-side.interactor";
import { Message } from "../../../../../shared/types/message.types";

@Component({
  selector: "app-single-image-or-video-uploader",
  templateUrl: "./single-image-or-video-uploader.component.html",
  styleUrl: "./single-image-or-video-uploader.component.scss",
})
export class SingleImageOrVideoUploaderComponent {
  @Input({ required: true }) public control!: FormControl<Media | undefined>;
  @Input({ required: true }) public type!: PublicationTemplateType;
  @Input({ required: true }) private fields!: PublicationTemplateFields;
  @Input({ required: true }) public form!: FormGroup;

  protected isLoading = false;
  protected currentError?: Message;

  private readonly validateFileClientSide =
    new ValidateFileClientSideInteractor();
  private readonly uploadPublicationTemplateMedia =
    new UploadPublicationTemplateMediaInteractor(
      inject(PublicationTemplateService),
    );

  protected getAllowedMimeTypes(): string[] {
    return this.fields.flatMap(
      (rule) => rule.rules.allowedMimeTypes?.constraint ?? [],
    );
  }

  protected onActionDeleteMedia(): void {
    this.control.setValue(undefined);
    this.form.markAsDirty();
  }

  protected async onUploadFile(files: File[]): Promise<void> {
    const file = files[0];

    if (!file) {
      return;
    }

    const field = this.fields.find((field) =>
      (field.rules.allowedMimeTypes?.constraint || []).includes(file.type),
    );

    // Handle unsupported file types
    if (!field) {
      const fileParts = file.name.split(".");
      const extension = fileParts[fileParts.length - 1];

      this.currentError = {
        key: "unsupportedFileType",
        params: {
          extension: extension ? `.${extension}` : "",
          type: file.type,
        },
      };

      return;
    }

    // Run client-side validation
    const rules = field.rules;
    const clientSideErrors = this.validateFileClientSide.execute(file, rules);

    if (clientSideErrors.length) {
      const firstError = clientSideErrors[0];

      this.currentError = {
        key: `${field.name}.${firstError.key}`,
        params: firstError.params,
      };

      return;
    }

    // Upload
    this.isLoading = true;

    try {
      const media = await this.uploadPublicationTemplateMedia.execute(
        this.type,
        field.name,
        file,
      );

      this.control.setValue(media);
    } catch (errorResponse: unknown) {
      if (errorResponse instanceof HttpErrorResponse) {
        const errorKey: string | undefined = errorResponse.error?.errorKey;

        if (errorKey) {
          this.currentError = {
            key: `${field.name}.${errorKey}`,
            params: this.getErrorParams(field.rules, errorKey),
          };
        } else {
          this.currentError = { key: `unknown`, params: {} };
        }

        return;
      }

      throw errorResponse;
    } finally {
      this.isLoading = false;
    }

    this.form.markAsDirty();
    this.currentError = undefined;
  }

  private getErrorParams(
    rules: Rules,
    errorKey: string,
  ): Record<string, string> {
    return rules[errorKey]
      ? {
          [errorKey]:
            rules[errorKey].constraintPretty ??
            String(rules[errorKey].constraint),
        }
      : {};
  }
}
