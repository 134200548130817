import { PublicationTemplateService } from "../../data/publication-template.service";
import { RecommendedSchedule } from "../models/publication-template";

export class CreateRecommendedScheduleInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
  ) {}

  public async execute(
    publicateionTemplateId: string,
    startDate: Date,
    endDate?: Date,
  ): Promise<RecommendedSchedule> {
    return this.publicationTemplateService.createRecommendedSchedule(
      publicateionTemplateId,
      startDate,
      endDate,
    );
  }
}
