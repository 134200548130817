import { Component, computed, input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { ImageMedia } from "../../../../../../../features/media/domain/models/media";
import { MediaPreviewComponent } from "../../../../../../../shared/components/media-preview/media-preview.component";
import {
  FacebookCarouselFields,
  getField,
} from "../../../../data/entities/publication-template-fields.entity";
import {
  PublicationTemplateLinkForm,
  PublicationTemplateLinkSelectorComponent,
} from "../../../publication-template-link-selector/publication-template-link-selector.component";

@Component({
  standalone: true,
  selector: "app-facebook-carousel-extra-slide-form",
  templateUrl: "./facebook-carousel-extra-slide-form.component.html",
  styleUrls: [
    "../facebook-carousel-slide-form/facebook-carousel-slide-form.component.scss",
    "./facebook-carousel-extra-slide-form.component.scss",
  ],
  imports: [
    MediaPreviewComponent,
    MatFormField,
    MatInput,
    MatHint,
    MatLabel,
    ReactiveFormsModule,
    MatIcon,
    MatError,
    TranslateModule,
    PublicationTemplateLinkSelectorComponent,
  ],
})
export class FacebookCarouselExtraSlideFormComponent {
  public fields = input.required<FacebookCarouselFields>();
  protected readonly form =
    input.required<FormGroup<PublicationTemplateLinkForm>>();

  protected maxLinkLength = computed<number>(
    () =>
      getField("landingPageUrl", this.fields()).rules.maxTextLength.constraint,
  );
  private readonly sampleImage = "/assets/images/company.png";
  protected readonly sampleFacebookMedia = new ImageMedia(
    "1",
    300,
    300,
    this.sampleImage,
    this.sampleImage,
  );
}
