import { FormGroupType, FormValue } from "../../../../../../harmony/angular";
import { ArrayMapper, Mapper } from "../../../../../../harmony/core";
import { MediaEntityToMediaMapper } from "../../../../../features/media/domain/mappers/media.mapper";
import { RecommendedScheduleEntityToRecommendedScheduleMapper } from "../../../../../features/media/domain/mappers/recommended-schedules.mapper";
import { ImageMedia } from "../../../../../features/media/domain/models/media";
import {
  FacebookCarouselEntity,
  FacebookCarouselSlideEntity,
  InstagramStoryEntity,
  PublicationTemplateEntity,
  PublicationTemplateType,
} from "../../data/entities/publication-templates.entity";
import {
  FacebookCarousel,
  FacebookCarouselSlide,
  InstagramStory,
  LinkType,
  PartialPublicationTemplate,
  PublicationTemplate,
} from "../models/publication-template";

export type FormToApiMapper<
  Form extends FormGroupType<Form>,
  CreateDto,
> = Mapper<FormValue<Form>, CreateDto>;

export type FormToPreviewMapper<
  Form extends FormGroupType<Form>,
  T extends PublicationTemplateType,
> = Mapper<FormValue<Form>, PartialPublicationTemplate<T>>;

export class PublicationTemplateEntityToPublicationTemplateMapper
  implements Mapper<PublicationTemplateEntity, PublicationTemplate>
{
  public map(from: PublicationTemplateEntity): PublicationTemplate {
    switch (from.publicationTemplateType) {
      case PublicationTemplateType.InstagramStory: {
        return mapInstagramStoryEntityToModel(from);
      }
      case PublicationTemplateType.FacebookCarousel: {
        return mapFacebookCarouselEntityToModel(from);
      }
      default:
        const pt: never = from;
        throw new Error(`Unknown publication template type: ${pt}`);
    }
  }
}

export class CarouselSlideMapper
  implements Mapper<FacebookCarouselSlideEntity, FacebookCarouselSlide>
{
  private readonly mediaMapper = new MediaEntityToMediaMapper();
  public map(from: FacebookCarouselSlideEntity): FacebookCarouselSlide {
    return new FacebookCarouselSlide(
      this.mediaMapper.map(from.media) as ImageMedia,
      from.title,
      from.landingPageType ?? LinkType.CustomUrl,
      from.landingPageUrl,
      from.description,
    );
  }
}

export const mapInstagramStoryEntityToModel = (
  from: InstagramStoryEntity,
): InstagramStory => {
  const mediaMapper = new MediaEntityToMediaMapper();
  const schedulesMapper = new ArrayMapper(
    new RecommendedScheduleEntityToRecommendedScheduleMapper(),
  );

  return new InstagramStory(
    from.id,
    mediaMapper.map(from.media),
    from.recommendedSchedules
      ? schedulesMapper
          .map(from.recommendedSchedules)
          .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
      : [],
  );
};

export const mapFacebookCarouselEntityToModel = (
  from: FacebookCarouselEntity,
): FacebookCarousel => {
  const slideMapper = new CarouselSlideMapper();
  const schedulesMapper = new ArrayMapper(
    new RecommendedScheduleEntityToRecommendedScheduleMapper(),
  );

  return new FacebookCarousel(
    from.id,
    from.slides.map((slide) => slideMapper.map(slide)),
    from.text,
    from.landingPageType,
    from.recommendedSchedules
      ? schedulesMapper
          .map(from.recommendedSchedules)
          .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
      : [],
    from.landingPageUrl,
  );
};
