import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Media } from "../../../../../features/media/domain/models/media";
import {
  Field,
  PublicationTemplateFields,
} from "../../data/entities/publication-template-fields.entity";
import { Rules } from "../../data/entities/publication-template-rules.entity";
import { UploadPublicationTemplateMediaInteractor } from "../../domain/interactors/upload-publication-template-media.interactor";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";
import { PublicationTemplateService } from "../../data/publication-template.service";
import { ValidateFileClientSideInteractor } from "../../domain/interactors/validate-file-client-side.interactor";
import { Message } from "../../../../../shared/types/message.types";

@Component({
  selector: "app-multiple-image-or-video-uploader",
  templateUrl: "./multiple-image-or-video-uploader.component.html",
  styleUrl: "./multiple-image-or-video-uploader.component.scss",
})
export class MultipleImageOrVideoUploaderComponent {
  @Input({ required: true }) public control!: FormControl<Media[]>;
  @Input({ required: true }) public type!: PublicationTemplateType;
  @Input({ required: true }) private fields!: PublicationTemplateFields;
  @Input({ required: true }) public form!: FormGroup;
  @Input({ required: true }) public maxNumFiles!: number;
  @Input({ required: true }) public availableNumFiles!: number;

  protected isLoading = false;
  protected currentError?: Message;

  private readonly validateFileClientSide =
    new ValidateFileClientSideInteractor();
  private readonly uploadPublicationTemplateMedia =
    new UploadPublicationTemplateMediaInteractor(
      inject(PublicationTemplateService),
    );

  protected getAllowedMimeTypes(): string[] {
    return this.fields.flatMap(
      (rule) => rule.rules.allowedMimeTypes?.constraint ?? [],
    );
  }

  protected async onUploadFiles(files: File[]): Promise<void> {
    if (!files.length) {
      return;
    }

    if (files.length > this.availableNumFiles) {
      this.currentError = {
        key: `maxNumFiles.${this.type}`,
        params: {
          maxNumFiles: this.maxNumFiles,
        },
      };
      return;
    }

    const mediaList = [];

    let field: Field<string, Rules> | undefined;
    for (const file of files) {
      field = this.fields.find((field) =>
        (field.rules.allowedMimeTypes?.constraint || []).includes(file.type),
      );

      if (!field) {
        const fileParts = file.name.split(".");
        const extension = fileParts[fileParts.length - 1];

        this.currentError = {
          key: "unsupportedFileType",
          params: {
            extension: extension ? `.${extension}` : "",
            type: file.type,
          },
        };

        return;
      }

      // Run client-side validation
      const clientSideErrors = this.validateFileClientSide.execute(
        file,
        field.rules,
      );

      if (clientSideErrors.length) {
        const firstError = clientSideErrors[0];

        this.currentError = {
          key: `${field.name}.${firstError.key}`,
          params: firstError.params,
        };

        return;
      }

      // Upload
      this.isLoading = true;

      try {
        const media = await this.uploadPublicationTemplateMedia.execute(
          this.type,
          field!.name,
          file,
        );

        mediaList.push(media);
      } catch (errorResponse: unknown) {
        if (errorResponse instanceof HttpErrorResponse) {
          const errorKey: string | undefined = errorResponse.error?.errorKey;

          if (errorKey) {
            this.currentError = {
              key: `${field.name}.${errorKey}`,
              params: this.getErrorParams(field.rules, errorKey),
            };
          } else {
            this.currentError = { key: `unknown`, params: {} };
          }

          return;
        }
        throw errorResponse;
      } finally {
        this.isLoading = false;
      }
    }

    this.control.setValue(mediaList);
    this.currentError = undefined;
  }

  private getErrorParams(
    rules: Rules,
    errorKey: string,
  ): Record<string, string> {
    return rules[errorKey]
      ? {
          [errorKey]:
            rules[errorKey].constraintPretty ??
            String(rules[errorKey].constraint),
        }
      : {};
  }
}
