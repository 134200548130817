<div class="section-container">
  <div class="media">
    <app-file-input
      [accept]="getAllowedMimeTypes()"
      [isDisabled]="isLoading || !!control.value"
      (selected)="onUploadFile($event)"
    />
    @if (control.value) {
      <app-media-preview
        [media]="control.value"
        (remove)="onActionDeleteMedia()"
      />
    }
    @if (isLoading) {
      <div class="loading">
        <mat-spinner color="accent" [diameter]="40" />
      </div>
    }
  </div>
  <div class="errors">
    @if (currentError) {
      <app-error-message [error]="currentError" prefix="media.error." />
    } @else if (form.touched && control.errors?.required) {
      <app-error-message key="publicationTemplate.update.error.required" />
    }
  </div>
</div>
