<app-media-preview
  class="media-preview"
  [media]="sampleFacebookMedia"
  [removable]="false"
/>
<form class="text-fields">
  <app-publication-template-link-selector
    [form]="form()"
    [maxLength]="maxLinkLength()"
  />
  <div class="hint">
    <mat-icon>info</mat-icon>
    <span>{{ "publicationTemplate.carousel.extraSlideHint" | translate }}</span>
  </div>
</form>
