import { FormValue } from "../../../../../../harmony/angular";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";
import {
  FormToApiMapper,
  FormToPreviewMapper,
} from "../../domain/mappers/publication-template.mapper";
import {
  LinkType,
  PartialPublicationTemplate,
} from "../../domain/models/publication-template";
import { FacebookCarouselForm } from "./facebook-carousel-form/facebook-carousel-form.component";
import { FacebookCarouselCreateDto } from "./facebook-carousel.create-dto";

export class FacebookCarouselFormToApiMapper
  implements FormToApiMapper<FacebookCarouselForm, FacebookCarouselCreateDto>
{
  public map(form: FormValue<FacebookCarouselForm>): FacebookCarouselCreateDto {
    return {
      text: form.text!,
      publicationTemplateType: PublicationTemplateType.FacebookCarousel,
      landingPageType: form.linkType,
      landingPageUrl:
        form.linkType === LinkType.CustomUrl && form.link
          ? form.link
          : undefined,
      slides: form.slides.map((slide) => ({
        mediaId: slide.media.id!,
        title: slide.title!,
        description: slide.description ?? undefined,
        landingPageUrl:
          slide.linkType === LinkType.CustomUrl && slide.link
            ? slide.link
            : undefined,
        landingPageType: slide.linkType!,
      })),
    };
  }
}

export class FacebookCarouselFormToPreviewMapper
  implements
    FormToPreviewMapper<
      FacebookCarouselForm,
      PublicationTemplateType.FacebookCarousel
    >
{
  public map(
    form: FormValue<FacebookCarouselForm>,
  ): PartialPublicationTemplate<PublicationTemplateType.FacebookCarousel> {
    return {
      text: form.text ?? undefined,
      publicationTemplateType: PublicationTemplateType.FacebookCarousel,
      slides: form.slides.map((slide) => ({
        media: slide.media ?? undefined,
        title: slide.title ?? undefined,
        linkType: slide.linkType ?? undefined,
        description: slide.description ?? undefined,
        link: slide.link ?? undefined,
      })),
    };
  }
}
