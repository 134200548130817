<div class="rules-box">
  <div class="rules-box__header" (click)="toggle()">
    <h4 class="rules-box__title">
      {{ "publicationTemplate.rules.instagramStory.requirements" | translate }}
    </h4>
    <mat-icon class="rules-box__header-icon">{{
      isOpen() ? "expand_less" : "expand_more"
    }}</mat-icon>
  </div>
  <section
    class="rules-box__content"
    [ngClass]="{ 'rules-box__content--open': isOpen() }"
  >
    <p>
      {{ "publicationTemplate.rules.instagramStory.description" | translate }}
    </p>
    <mat-tab-group class="mo-compact-tabs">
      <mat-tab bodyClass="tab-content" label="{{ 'shared.image' | translate }}">
        <app-rules-list [rules]="imageRules()" />
      </mat-tab>
      <mat-tab bodyClass="tab-content" label="{{ 'shared.video' | translate }}">
        <app-rules-list [rules]="videoRules()" />
      </mat-tab>
    </mat-tab-group>
    <div class="details">
      <div
        class="details__content"
        [ngClass]="{ 'details__content--open': isDetailsOpen() }"
      >
        <h4>{{ "publicationTemplate.rules.limitations" | translate }}</h4>
        <ul>
          @for (detail of details; track $index) {
            <li>{{ detail }}</li>
          }
        </ul>
      </div>
      <span class="details__toggle" (click)="toggleDetails()">
        {{
          (isDetailsOpen()
            ? "publicationTemplate.rules.showLess"
            : "publicationTemplate.rules.showMore"
          ) | translate
        }}
      </span>
    </div>
  </section>
</div>
