import {
  ImageMedia,
  Media,
} from "../../../../../features/media/domain/models/media";
import { Platform } from "../../../../../shared/enums/campaignPost.enums";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";

export enum PublicationTemplateStatus {
  Published = "PUBLISHED",
  Scheduled = "SCHEDULED",
  WithIssue = "WITH_ISSUE",
  NotAccepted = "NOT_ACCEPTED",
}

export const PublicationTemplatePlatform: Record<
  PublicationTemplateType,
  Platform
> = {
  [PublicationTemplateType.FacebookCarousel]: Platform.Facebook,
  [PublicationTemplateType.InstagramStory]: Platform.Instagram,
};

export class RecommendedSchedule {
  constructor(
    public readonly id: string,
    public readonly publicationTemplateId: string,
    public readonly startDate: Date,
    public readonly endDate?: Date,
  ) {}
}

interface IPublicationTemplate {
  readonly id: string;
  readonly publicationTemplateType: PublicationTemplateType;
  readonly platform: Platform;
  readonly recommendedSchedules: RecommendedSchedule[];
}

export class InstagramStory implements IPublicationTemplate {
  public readonly publicationTemplateType =
    PublicationTemplateType.InstagramStory;
  public readonly platform = Platform.Instagram;

  constructor(
    public readonly id: string,
    public readonly media: Media,
    public readonly recommendedSchedules: RecommendedSchedule[],
  ) {}
}

export enum LinkType {
  CampaignUrl = "CAMPAIGN_URL",
  PublisherUrl = "PARTNER_URL",
  CustomUrl = "SPECIFIC_URL",
}

// todo: move fb carousel to specific file
export class FacebookCarouselSlide {
  constructor(
    public readonly media: ImageMedia,
    public readonly title: string,
    public readonly linkType: LinkType,
    public readonly link?: string,
    public readonly description?: string,
  ) {}
}

export class FacebookCarousel implements IPublicationTemplate {
  public readonly publicationTemplateType =
    PublicationTemplateType.FacebookCarousel;
  public readonly platform = Platform.Facebook;

  constructor(
    public readonly id: string,
    public readonly slides: FacebookCarouselSlide[],
    public readonly text: string,
    public readonly linkType: LinkType,
    public readonly recommendedSchedules: RecommendedSchedule[],
    public readonly link?: string,
  ) {}
}

export type PublicationTemplate = InstagramStory | FacebookCarousel;

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export type PartialPublicationTemplate<Type extends PublicationTemplateType> = {
  publicationTemplateType: Type;
} & DeepPartial<PublicationTemplate>;
