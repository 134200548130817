import { NgClass } from "@angular/common";
import { Component, EventEmitter, Output, signal } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatCard } from "@angular/material/card";
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatCalendar,
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate,
} from "@angular/material/datepicker";
import {
  MatError,
  MatFormField,
  MatHint,
  MatPrefix,
} from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import {
  FilterIcon,
  FilterOutput,
  FilterType,
  SelectorOption,
} from "../filter.interfaces";

@Component({
  standalone: true,
  selector: "app-time-period-selector",
  templateUrl: "./time-period-selector.component.html",
  styleUrl: "./time-period-selector.component.scss",
  imports: [
    NgClass,
    MatPrefix,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCalendar,
    MatCard,
    TranslateModule,
    MatFormField,
    MatDateRangeInput,
    MatDatepickerToggle,
    MatDateRangePicker,
    MatError,
    MatHint,
    MatInput,
    MatEndDate,
    MatStartDate,
  ],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    {
      provide: DateAdapter,
      useFactory: adapterFactory,
    },
  ],
})
export class TimePeriodSelectorComponent {
  @Output() public optionSelected = new EventEmitter<FilterOutput[]>();

  protected options = signal<SelectorOption[]>([]);
  protected areOptionsShown = signal(false);
  protected isLoading = signal<boolean>(false);
  protected readonly FilterIcon = FilterIcon;

  protected readonly form = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  protected readonly FilterType = FilterType;

  protected selectedDateRange?: DateRange<Date>;
  protected onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date,
      );
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }

    this.optionSelected.emit([
      ...(this.selectedDateRange.start
        ? [
            {
              value: {
                id: this.selectedDateRange.start,
                name: "start",
              },
              type: FilterType.TimePeriod,
            },
          ]
        : []),
      ...(this.selectedDateRange.end
        ? [
            {
              value: {
                id: this.selectedDateRange.end,
                name: "end",
              },
              type: FilterType.TimePeriod,
            },
          ]
        : []),
    ]);
  }
}
