import { Component, Inject, OnDestroy, signal } from "@angular/core";
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { BrandCampaign } from "../../../../../shared/models/brandCampaign";
import {
  TimezoneOffset,
  TIMEZONES_OFFSET,
} from "../../../../../shared/services/calendar.data";
import { CreateRecommendedScheduleInteractor } from "../../domain/interactors/create-recommended-schedule.interactor";
import { DeleteRecommendedScheduleInteractor } from "../../domain/interactors/delete-recommended-schedule.interactor";
import { UpdateRecommendedScheduleInteractor } from "../../domain/interactors/update-recommended-schedule.interactor";
import { RecommendedSchedule } from "../../domain/models/publication-template";

export interface SchedulePublicationTemplateDialogData {
  readonly campaign: BrandCampaign;
  readonly publicationTemplateId: string;
  readonly date?: Date;
  readonly schedules: RecommendedSchedule[];
  readonly scheduleId?: string;
}

export type SchedulePublicationTemplateDialogResult =
  | {
      readonly schedules: RecommendedSchedule[];
    }
  | undefined;

interface RecommendedScheduleForm {
  readonly startDate: FormControl<Date | null>;
  readonly startHours: FormControl<number | null>;
  readonly startMinutes: FormControl<number | null>;
  // readonly timezoneOffset: FormControl<number | null>;
}

@Component({
  selector: "app-schedule-publication-template-dialog",
  templateUrl: "./schedule-publication-template-dialog.component.html",
  styleUrl: "./schedule-publication-template-dialog.component.scss",
})
export class SchedulePublicationTemplateDialogComponent implements OnDestroy {
  protected readonly form: FormGroup<RecommendedScheduleForm>;
  protected readonly campaign: BrandCampaign;
  protected readonly loading = signal(false);
  protected readonly isEventDragged = signal(false);
  protected readonly scheduleId?: string;
  protected readonly schedules: RecommendedSchedule[];
  protected readonly publicationTemplateId: string;
  protected readonly minSelectableDate: Date;
  protected campaignTime?: string;
  protected readonly hours = Array.from({ length: 24 }, (_, index) =>
    index < 10 ? `0${index}` : `${index}`,
  );
  protected readonly minutes = Array.from({ length: 60 }, (_, index) =>
    index < 10 ? `0${index}` : `${index}`,
  );
  protected readonly NOW = new Date();
  protected readonly TIMEZONES_BY_OFFSET = TIMEZONES_OFFSET.reduce<
    Record<number, TimezoneOffset>
  >((acc, timezone) => {
    acc[timezone.offset] = timezone;
    return acc;
  }, {});
  protected readonly subs = new Subscription();
  protected userTimezone?: TimezoneOffset;

  constructor(
    private readonly dialogRef: MatDialogRef<
      SchedulePublicationTemplateDialogComponent,
      SchedulePublicationTemplateDialogResult
    >,
    private readonly updateRecommendedSchedule: UpdateRecommendedScheduleInteractor,
    private readonly createRecommendedSchedule: CreateRecommendedScheduleInteractor,
    private readonly deleteRecommendedSchedule: DeleteRecommendedScheduleInteractor,
    private readonly fb: NonNullableFormBuilder,
    @Inject(MAT_DIALOG_DATA) data: SchedulePublicationTemplateDialogData,
  ) {
    this.campaign = data.campaign;
    this.scheduleId = data.scheduleId;
    this.schedules = data.schedules;
    this.publicationTemplateId = data.publicationTemplateId;

    this.minSelectableDate = new Date(
      Math.max(new Date().getTime(), this.campaign.startDate.getTime()),
    );

    let startDate = data.date;
    let startHours = startDate?.getHours() ?? 0;
    let startMinutes = startDate?.getMinutes() ?? 0;

    if (startDate) {
      startDate = this.getAtLeastInOneHourDate(startDate);

      const schedule = this.getSchedule(data.scheduleId);
      if (schedule) {
        this.isEventDragged.set(true);
        startHours = schedule.startDate.getHours();
        startMinutes = schedule.startDate.getMinutes();
      } else {
        startHours = startDate?.getHours() ?? 0;
        startMinutes = startDate?.getMinutes() ?? 0;
        const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;
        startDate = new Date(startDate.getTime() - timeOffset);
      }
    }

    if (!startDate && data.scheduleId) {
      startDate = this.getSchedule(data.scheduleId).startDate;
      startHours = startDate?.getHours() ?? 0;
      startMinutes = startDate?.getMinutes() ?? 0;

      const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;
      startDate = new Date(startDate.getTime() - timeOffset);
    }

    if (!startDate) {
      startDate = this.getThreeWeeksFromNowOrLastCampaignDate();
    }

    this.campaignTime = this.getCampaignStartingHourWarning(startDate);

    const userTimezoneOffset = new Date().getTimezoneOffset();
    this.userTimezone = TIMEZONES_OFFSET.find(
      (timezone) => timezone.offset === userTimezoneOffset,
    );

    this.form = this.fb.group<RecommendedScheduleForm>({
      startDate: new FormControl<Date | null>(startDate ?? null, [
        Validators.required,
      ]),
      startHours: new FormControl<number | null>(startHours, [
        Validators.required,
      ]),
      startMinutes: new FormControl<number | null>(startMinutes, [
        Validators.required,
      ]),
    });

    this.subs.add(
      this.form.valueChanges.subscribe((form) => {
        if (!form.startDate) {
          return;
        }

        this.campaignTime = this.getCampaignStartingHourWarning(form.startDate);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected async save(): Promise<void> {
    this.loading.set(true);

    this.form.markAsTouched();

    const localDate = new Date(this.form.controls.startDate.value!);

    localDate.setHours(+this.form.controls.startHours.value!);
    localDate.setMinutes(+this.form.controls.startMinutes.value!);

    const inOneHour = new Date().getTime() + 60 * 60 * 1000;

    if (localDate.getTime() < this.campaign.startDate.getTime()) {
      this.form.controls.startDate.setErrors({ matDatepickerMin: true });
      this.loading.set(false);
      return;
    }

    if (localDate.getTime() > this.campaign.endDate.getTime()) {
      this.form.controls.startDate.setErrors({ matDatepickerMax: true });
      this.loading.set(false);
      return;
    }

    if (localDate.getTime() < inOneHour) {
      this.form.controls.startDate.setErrors({ timeSpace: true });
      this.loading.set(false);
      return;
    }

    if (this.scheduleId) {
      await this.updateSchedule(localDate);
    } else {
      await this.createSchedule(localDate);
    }
  }

  private getSchedule(id?: string): RecommendedSchedule {
    return this.schedules.find((schedule) => schedule.id === id)!;
  }

  protected async deleteSchedule(): Promise<void> {
    if (!this.scheduleId) {
      return;
    }

    await this.deleteRecommendedSchedule.execute(this.scheduleId);
    this.loading.set(false);
    this.dialogRef.close({
      schedules: this.schedules.filter(
        (schedule) => schedule.id !== this.scheduleId,
      ),
    });
  }

  protected async updateSchedule(startDate: Date): Promise<void> {
    const updatedSchedule = await this.updateRecommendedSchedule.execute({
      ...this.schedules.find((schedule) => schedule.id === this.scheduleId)!,
      startDate: startDate,
    });
    this.loading.set(false);
    this.dialogRef.close({
      schedules: this.schedules.map((schedule) =>
        schedule.id === this.scheduleId ? updatedSchedule : schedule,
      ),
    });
  }

  protected async createSchedule(startDate: Date): Promise<void> {
    const createdSchedule = await this.createRecommendedSchedule.execute(
      this.publicationTemplateId,
      startDate,
    );
    this.loading.set(false);
    const schedules = [...this.schedules, createdSchedule];
    schedules.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    this.dialogRef.close({
      schedules: schedules,
    });
  }

  private getAtLeastInOneHourDate(date: Date): Date {
    const inOneHourAndAMinute =
      new Date().getTime() + 60 * 60 * 1000 + 60 * 1000;
    return new Date(Math.max(date.getTime(), inOneHourAndAMinute));
  }

  private getThreeWeeksFromNowOrLastCampaignDate(): Date {
    const ThreeWeeksInSeconds = 3 * 7 * 24 * 60 * 60 * 1000;
    return new Date(
      Math.min(
        this.NOW.getTime() + ThreeWeeksInSeconds,
        this.campaign.endDate.getTime(),
      ),
    );
  }

  private getCampaignStartingHourWarning(chosenDate: Date): string | undefined {
    const isMidnight =
      this.campaign.startDate.toLocaleTimeString("en-GB", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      }) === "00:00";

    const isFirstDay = this.campaign.startDate.isSameDay(chosenDate);
    const isLastDay = this.campaign.endDate.isSameDay(chosenDate);

    return (isFirstDay || isLastDay) && !isMidnight
      ? this.campaign.startDate.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })
      : undefined;
  }

  protected get isStartingDay(): boolean {
    if (!this.form.controls.startDate.value) {
      return false;
    }

    return this.campaign.startDate.isSameDay(
      this.form.controls.startDate.value,
    );
  }

  protected get isEndingDay(): boolean {
    if (!this.form.controls.startDate.value) {
      return false;
    }

    return this.campaign.endDate.isSameDay(this.form.controls.startDate.value);
  }
}
