import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";
import { PublicationTemplateService } from "../../data/publication-template.service";

export class GetPublicationTemplateTypesByCampaignInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
  ) {}

  public async execute(campaignId: number): Promise<PublicationTemplateType[]> {
    return this.publicationTemplateService.getTypesByCampaign(campaignId);
  }
}
