import { Media } from "../../../../../features/media/domain/models/media";
import { PublicationTemplateService } from "../../data/publication-template.service";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";

export class UploadPublicationTemplateMediaInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
  ) {}

  public execute(
    type: PublicationTemplateType,
    field: string,
    file: File,
  ): Promise<Media> {
    return this.publicationTemplateService.uploadMedia(type, field, file);
  }
}
