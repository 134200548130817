import { FileValidator } from "../validate-file-client-side.interactor";

export const maxFileSizeInBytes: FileValidator<number> = (
  file,
  { constraint, constraintPretty },
) =>
  file.size > constraint
    ? {
        key: "maxFileSizeInBytes",
        params: { maxFileSizeInBytes: constraintPretty },
      }
    : undefined;
