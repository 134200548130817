<app-page-header
  [title]="
    publicationTemplateType
      ? 'publicationTemplate.schedule.' +
        (publicationTemplateType | caseTransform: 'SnakeToCamel') +
        '.title'
      : ''
  "
  [backLink]="
    isEdit
      ? BrandUrl.Campaign.Publications(brandId, campaignId)
      : BrandUrl.Campaign.PublicationTemplate(brandId, campaignId).Selection
  "
>
  <button
    class="btn btn--dark"
    (click)="onSave()"
    [disabled]="!form || form?.invalid || !form?.dirty"
  >
    {{ "publicationTemplate.create.save" | translate }}
  </button>
</app-page-header>
<div class="main">
  <div class="content-wrapper">
    <div class="content">
      <h3 class="title">{{ "shared.content" | translate }}</h3>
      <div class="rules-wrapper">
        <ng-container #rulesContainer />
      </div>
      <ng-container #formContainer />
    </div>
  </div>
  <div class="preview-section">
    <div class="preview">
      @if (publicationTemplate) {
        <div class="post-header">
          <div class="section">
            <img
              [src]="'/assets/images/icons/ic_' + platform + 'Content.svg'"
              class="header-icon"
              alt="instagram-icon"
            />
            <h3 class="post-type">
              {{
                "publicationTemplatePostContainer." +
                  (publicationTemplate.publicationTemplateType
                    | caseTransform: "SnakeToCamel") | translate
              }}
            </h3>
          </div>
        </div>
      }
      <div class="preview-container">
        @if (publicationTemplatePreviewData) {
          <app-publication-template-preview
            [data]="publicationTemplatePreviewData"
          />
        }
      </div>
      <app-publication-template-schedules
        [campaign]="campaign"
        [publicationTemplateId]="publicationTemplate?.id"
        [schedules]="publicationTemplate?.recommendedSchedules"
        (goToScheduleWithEdition)="onGoToSchedule($event)"
      />
    </div>
  </div>
</div>
