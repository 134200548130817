import { Cached } from "../../../harmony/core";
import { BrandCampaignInvitePartnersService } from "../../brand/brand-campaign/brand-campaign-invite-partners/brand-campaign-invite-partners.service";
import { BrandCampaignPartnersService } from "../../brand/brand-campaign/shared/services/brand-campaign-partners.service";
import { CancelPartnersSheetImportInteractor } from "./domain/interactors/cancel-partners-sheet-import.interactor";
import { ExtendPartnersSheetImportTimeInteractor } from "./domain/interactors/extend-partners-sheet-import-time.interactor";
import { GetCampaignInvitedPartnersInteractor } from "./domain/interactors/get-campaign-invited-partners.interactor";
import { GetPartnersSheetProcessingProgressInteractor } from "./domain/interactors/get-partners-sheet-processing-progress.interactor";
import { GetPartnersSheetProcessingResultInteractor } from "./domain/interactors/get-partners-sheet-processing-result.interactor";
import { GetPartnersSheetStateInteractor } from "./domain/interactors/get-partners-sheet-state.interactor";
import { ImportPartnersInteractor } from "./domain/interactors/import-partners.interactor";
import { UploadPartnersSheetInteractor } from "./domain/interactors/upload-partners-sheet.interactor";

export abstract class BrandCampaignProvider {
  abstract provideCancelPartnersSheetImport(): CancelPartnersSheetImportInteractor;
  abstract provideExtendPartnersSheetImportTime(): ExtendPartnersSheetImportTimeInteractor;
  abstract provideGetCampaignInvitedPartners(): GetCampaignInvitedPartnersInteractor;
  abstract provideGetPartnersSheetProcessingProgress(): GetPartnersSheetProcessingProgressInteractor;
  abstract provideGetPartnersSheetProcessingResult(): GetPartnersSheetProcessingResultInteractor;
  abstract provideGetPartnersSheetState(): GetPartnersSheetStateInteractor;
  abstract provideImportPartners(): ImportPartnersInteractor;
  abstract provideUploadPartnersSheet(): UploadPartnersSheetInteractor;
}

export class BrandCampaignDefaultProvider implements BrandCampaignProvider {
  constructor(
    private readonly brandCampaignPartnersService: BrandCampaignPartnersService,
    private readonly invitePartnersService: BrandCampaignInvitePartnersService,
  ) {}

  @Cached()
  public provideCancelPartnersSheetImport(): CancelPartnersSheetImportInteractor {
    return new CancelPartnersSheetImportInteractor(this.invitePartnersService);
  }

  @Cached()
  public provideExtendPartnersSheetImportTime(): ExtendPartnersSheetImportTimeInteractor {
    return new ExtendPartnersSheetImportTimeInteractor(
      this.invitePartnersService,
    );
  }

  @Cached()
  public provideGetCampaignInvitedPartners(): GetCampaignInvitedPartnersInteractor {
    return new GetCampaignInvitedPartnersInteractor(
      this.brandCampaignPartnersService,
    );
  }

  @Cached()
  public provideGetPartnersSheetProcessingProgress(): GetPartnersSheetProcessingProgressInteractor {
    return new GetPartnersSheetProcessingProgressInteractor(
      this.invitePartnersService,
    );
  }

  @Cached()
  public provideGetPartnersSheetProcessingResult(): GetPartnersSheetProcessingResultInteractor {
    return new GetPartnersSheetProcessingResultInteractor(
      this.invitePartnersService,
    );
  }

  @Cached()
  public provideGetPartnersSheetState(): GetPartnersSheetStateInteractor {
    return new GetPartnersSheetStateInteractor(
      this.provideGetPartnersSheetProcessingProgress(),
    );
  }

  @Cached()
  public provideImportPartners(): ImportPartnersInteractor {
    return new ImportPartnersInteractor(this.invitePartnersService);
  }

  @Cached()
  public provideUploadPartnersSheet(): UploadPartnersSheetInteractor {
    return new UploadPartnersSheetInteractor(this.invitePartnersService);
  }
}
