import { NotificationService } from "../../../../../shared/services/notification.service";
import {
  PublicationTemplateType,
  PublicationTemplateEntity,
} from "../../data/entities/publication-templates.entity";
import { PublicationTemplateService } from "../../data/publication-template.service";
import { PublicationTemplate } from "../models/publication-template";

export class CreatePublicationTemplateInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
    private readonly notificationService: NotificationService,
  ) {}

  public async execute(
    brandId: number,
    campaignId: number,
    type: PublicationTemplateType,
    data: PublicationTemplateEntity,
  ): Promise<PublicationTemplate> {
    try {
      return await this.publicationTemplateService.create(
        brandId,
        campaignId,
        type,
        data,
      );
    } catch (error) {
      this.notificationService.error("publicationTemplate.create.error");
      throw error;
    }
  }
}
