import {
  Component,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Platform } from "../../../../../shared/enums/campaignPost.enums";
import { BrandCampaign } from "../../../../../shared/models/brandCampaign";
import { DialogService } from "../../../../../shared/services/dialog.service";
import { NotFoundService } from "../../../../../shared/services/not-found.service";
import { assert } from "../../../../../shared/utils/assert";
import { BrandCampaignService } from "../../../shared/services/brand-campaign.service";
import { PublicationTemplateForm } from "../../components/publication-template-form/publication-template-form";
import {
  PublicationTemplateEntity,
  PublicationTemplateType,
} from "../../data/entities/publication-templates.entity";
import { CreatePublicationTemplateInteractor } from "../../domain/interactors/create-publication-template.interactor";
import { GetPublicationTemplateFieldsInteractor } from "../../domain/interactors/get-publication-template-fields.interactor";
import { BrandService } from "../../../../../shared/services/api/brand.service";
import { BrandUrl } from "../../../../brand.url";
import { UpdatePublicationTemplateInteractor } from "../../domain/interactors/update-publication-template.interactor";
import {
  PartialPublicationTemplate,
  PublicationTemplate,
  PublicationTemplatePlatform,
} from "../../domain/models/publication-template";
import {
  PUBLICATION_TEMPLATES,
  PublicationTemplateConfig,
} from "../../publication-templates/publication-templates.config";
import { GetPublicationTemplateInteractor } from "../../domain/interactors/get-publication-template.interactor";
import {
  FormToApiMapper,
  FormToPreviewMapper,
} from "../../domain/mappers/publication-template.mapper";

@Component({
  selector: "app-publication-template-upsert",
  templateUrl: "./publication-template-upsert.component.html",
  styleUrl: "./publication-template-upsert.component.scss",
})
export class PublicationTemplateUpsertComponent implements OnInit {
  protected readonly BrandUrl = BrandUrl;
  protected readonly brandId: number;
  protected readonly isEdit: boolean;

  protected publicationTemplateType?: PublicationTemplateType;
  protected platform?: Platform;
  protected publicationTemplate?: PublicationTemplate;
  protected publicationTemplatePreviewData?: PartialPublicationTemplate<any>;

  protected campaign?: BrandCampaign;
  protected campaignId: number;
  protected form?: FormGroup;

  private config?: PublicationTemplateConfig;
  private formToApiMapper?: FormToApiMapper<any, PublicationTemplateEntity>;
  private formToPreviewMapper?: FormToPreviewMapper<any, any>;

  @ViewChild("rulesContainer", { read: ViewContainerRef, static: true })
  protected readonly rulesViewContainerRef!: ViewContainerRef;
  @ViewChild("formContainer", { read: ViewContainerRef, static: true })
  protected readonly formViewContainerRef!: ViewContainerRef;

  constructor(
    brandService: BrandService,
    private readonly createPublicationTemplate: CreatePublicationTemplateInteractor,
    private readonly updatePublicationTemplate: UpdatePublicationTemplateInteractor,
    private readonly getPublicationTemplate: GetPublicationTemplateInteractor,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly getFields: GetPublicationTemplateFieldsInteractor,
    private readonly notFoundService: NotFoundService,
    private readonly brandCampaignService: BrandCampaignService,
    private readonly dialogService: DialogService,
  ) {
    this.brandId = brandService.currentBrandId;
    this.campaignId = Number(this.route.snapshot.params.campaignId);

    this.isEdit = !!this.route.snapshot.params.publicationTemplateId;
  }

  public async ngOnInit(): Promise<void> {
    this.config = await this.loadConfig();
    this.formToApiMapper = new this.config.formToApi();
    this.formToPreviewMapper = new this.config.formToPreview();

    try {
      this.campaign = this.brandCampaignService.currentBrandCampaign;
    } catch (e) {
      this.campaign = await this.brandCampaignService.loadBrandCampaign(
        this.campaignId,
      );
    }

    await this.loadComponents(this.config);
  }

  private async loadConfig(): Promise<PublicationTemplateConfig> {
    let config: PublicationTemplateConfig | undefined;

    if (this.isEdit) {
      this.publicationTemplate = await this.getPublicationTemplate.execute(
        this.route.snapshot.params.publicationTemplateId,
      );

      config = PUBLICATION_TEMPLATES.find(
        (config) =>
          config.type === this.publicationTemplate!.publicationTemplateType,
      );
    } else {
      config = this.route.snapshot.data as PublicationTemplateConfig;
    }

    if (!config) {
      this.notFoundService.redirect("pt-config-not-found");
    }

    assert(!!config);
    this.publicationTemplateType = config.type;
    this.platform = PublicationTemplatePlatform[this.publicationTemplateType!];
    return config;
  }

  private async loadComponents(
    config: PublicationTemplateConfig,
  ): Promise<void> {
    const { rulesBox, form } = config;
    let formRef: ComponentRef<PublicationTemplateForm<any>>;

    try {
      const fields = await this.getFields.execute(config.type);

      const rulesBoxRef = this.rulesViewContainerRef.createComponent(rulesBox);
      assert(!!rulesBoxRef.instance);
      rulesBoxRef.setInput("fields", fields);

      formRef = this.formViewContainerRef.createComponent(form);
      assert(!!formRef.instance);
      formRef.instance.fields = fields;
      formRef.instance.createForm();

      if (this.publicationTemplate) {
        formRef.instance.setFormValues(this.publicationTemplate);
        this.publicationTemplatePreviewData = this.publicationTemplate;
      } else {
        this.publicationTemplatePreviewData = {
          publicationTemplateType: this.publicationTemplateType!,
        } as PartialPublicationTemplate<any>;
      }

      this.form = formRef.instance.form;
    } catch (error) {
      console.error(error);
      await this.router.navigateByUrl(
        BrandUrl.Campaign.Publications(this.brandId, this.campaignId),
      );
      return;
    }

    this.form?.valueChanges.subscribe(() => {
      assert(!!this.formToPreviewMapper);

      this.publicationTemplatePreviewData = this.formToPreviewMapper.map(
        this.form?.getRawValue(),
      );
    });
  }

  protected async onSave(): Promise<void> {
    assert(!!this.form);
    assert(!!this.formToApiMapper);

    await this.submit();

    await this.router.navigate([
      BrandUrl.Campaign.Publications(this.brandId, this.campaignId),
    ]);
  }

  protected async submit(): Promise<string> {
    assert(!!this.form);
    assert(!!this.formToApiMapper);

    if (this.isEdit) {
      assert(!!this.publicationTemplate);
      const publicationTemplate = await this.updatePublicationTemplate.execute(
        this.publicationTemplate,
        this.formToApiMapper.map(this.form.getRawValue()),
      );
      return publicationTemplate.id;
    } else {
      assert(!!this.config);
      const publicationTemplate = await this.createPublicationTemplate.execute(
        this.brandId,
        this.campaignId,
        this.config.type,
        this.formToApiMapper.map(this.form.getRawValue()),
      );
      return publicationTemplate.id;
    }
  }

  protected async onGoToSchedule(withEdition: boolean): Promise<void> {
    assert(this.form);

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    if (this.form.dirty) {
      await this.dialogService.confirm({
        title: "publicationTemplate.update.saveChanges.title",
        message: "publicationTemplate.update.saveChanges.message",
        onConfirm: async () => {
          const publicationTemplateId = await this.submit();
          this.goToScheduling(publicationTemplateId, withEdition);
        },
      });
      return;
    }

    assert(this.publicationTemplate);
    await this.goToScheduling(this.publicationTemplate.id, withEdition);
  }

  private async goToScheduling(
    publicationTemplateId: string,
    withRedirect: boolean,
  ): Promise<void> {
    const params = withRedirect ? { schedule: true } : {};
    await this.router.navigate(
      [
        BrandUrl.Campaign.PublicationTemplate(
          this.brandId,
          this.campaignId,
        ).Schedule(publicationTemplateId),
      ],
      { queryParams: params },
    );
  }
}
