import { MediaType } from "../../data/entities/media.entity";

class VisualMedia {
  constructor(
    public readonly id: string,
    public readonly width: number,
    public readonly height: number,
    public readonly link: string,
    public readonly thumbnailLink: string,
  ) {}
}

export class ImageMedia extends VisualMedia {
  public readonly mediaType = MediaType.Image;

  constructor(
    public readonly id: string,
    public readonly width: number,
    public readonly height: number,
    public readonly link: string,
    public readonly thumbnailLink: string,
    public readonly colors?: string,
    public readonly isSRGB?: boolean,
  ) {
    super(id, width, height, link, thumbnailLink);
  }
}

export class VideoMedia extends VisualMedia {
  public readonly mediaType = MediaType.Video;

  constructor(
    public readonly id: string,
    public readonly width: number,
    public readonly height: number,
    public readonly link: string,
    public readonly thumbnailLink: string,
    public readonly lengthInSeconds?: number,
    public readonly frameRate?: number,
    public readonly audioBitrate?: number,
    public readonly audioDecoder?: string,
    public readonly audioNumOfChannels?: number,
    public readonly audioSamplingRate?: number,
    public readonly videoBitrate?: number,
    public readonly videoDecoder?: string,
  ) {
    super(id, width, height, link, thumbnailLink);
  }
}

export type Media = ImageMedia | VideoMedia;
