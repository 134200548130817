import { Component, Input } from "@angular/core";
import { Platform } from "../../../../../shared/enums/campaignPost.enums";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";

@Component({
  selector: "app-publication-template-thumbnail",
  templateUrl: "./publication-template-thumbnail.component.html",
  styleUrl: "./publication-template-thumbnail.component.scss",
})
export class PublicationTemplateThumbnailComponent {
  @Input({ required: true }) protected platform!: Platform;
  @Input({ required: true }) protected type!: PublicationTemplateType;
  @Input({ required: true }) protected formRoute!: string;
  @Input() protected disabled = false;
}
