<app-page-header
  title="publicationTemplate.types.title"
  [backLink]="BrandUrl.Campaign.Publications(brandId, campaignId)"
/>
<div class="container">
  @for (typesAndPlatform of vm; track typesAndPlatform.platform) {
    <div class="platform">
      <div class="platform__title">
        <img
          class="platform__icon"
          [alt]="typesAndPlatform.platform"
          [src]="
            '/assets/images/icons/ic_' +
            (typesAndPlatform.platform | lowercase) +
            '.svg'
          "
        />
        <span>{{
          "publicationTemplate.thumbnail.title." +
            (typesAndPlatform.platform | caseTransform: "SnakeToCamel")
            | translate
        }}</span>
      </div>
      <div class="types-row">
        @for (type of typesAndPlatform.types; track type) {
          <app-publication-template-thumbnail
            class="thumbnail"
            [platform]="typesAndPlatform.platform"
            [formRoute]="
              BrandUrl.Campaign.PublicationTemplate(brandId, campaignId).New(
                type
              )
            "
            [type]="type"
            [disabled]="!PUBLICATION_TEMPLATES_MAP.has(type)"
          />
        }
      </div>
    </div>
  }
</div>
