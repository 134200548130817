import { NgClass } from "@angular/common";
import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { FacebookCarouselSlide } from "../../../../domain/models/publication-template";

@Component({
  standalone: true,
  selector: "app-facebook-carousel-slide-preview",
  templateUrl: "./facebook-carousel-slide-preview.component.html",
  styleUrls: ["./facebook-carousel-slide-preview.component.scss"],
  imports: [TranslateModule, NgClass],
})
export class FacebookCarouselSlidePreviewComponent {
  protected readonly slide = input<FacebookCarouselSlide | undefined>();
  protected readonly isExtraSlide = input(false);
}
