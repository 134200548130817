import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  signal,
} from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { NgClass } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PublicationTemplateRulesBoxComponent } from "../../../components/publication-template-rules-box/publication-template-rules-box.component";
import {
  RuleItemVM,
  RuleList,
  RulesListComponent,
} from "../../../components/publication-template-rules-box/rules-list/rules-list.component";
import { MatIconModule } from "@angular/material/icon";
import {
  getImageField,
  getVideoField,
  FacebookCarouselFields,
} from "../../../data/entities/publication-template-fields.entity";

@Component({
  standalone: true,
  selector: "app-facebook-carousel-rules-box",
  templateUrl: "./facebook-carousel-rules-box.component.html",
  styleUrls: ["./facebook-carousel-rules-box.component.scss"],
  imports: [
    NgClass,
    MatIconModule,
    MatTabsModule,
    TranslateModule,
    RulesListComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookCarouselRulesBoxComponent extends PublicationTemplateRulesBoxComponent<FacebookCarouselFields> {
  public fields = input.required<FacebookCarouselFields>();

  protected isOpen = signal(false);
  protected isDetailsOpen = signal(false);
  protected details = (
    inject(TranslateService).instant(
      "publicationTemplate.rules.facebookCarousel.details",
    ) as string
  )
    .split("|")
    .map((detail) => detail.trim());

  protected imageRules = computed<RuleList>(() => {
    const rules = getImageField("image", this.fields()).rules;

    return [
      new RuleItemVM(
        "category.aspectRatio",
        "facebookCarousel.image.aspectRatio",
        {
          aspectRatio: rules.aspectRatio.constraintPretty,
        },
      ),
      new RuleItemVM("category.width", "facebookCarousel.image.width", {
        min: rules.minWidth.constraint,
        max: rules.maxWidth.constraint,
      }),
      new RuleItemVM("category.height", "facebookCarousel.image.height", {
        min: rules.minHeight.constraint,
        max: rules.maxHeight.constraint,
      }),
      new RuleItemVM("category.format", "facebookCarousel.image.format", {
        format: rules.allowedMimeTypes.constraintPretty,
      }),
      new RuleItemVM("category.fileSize", "facebookCarousel.image.fileSize", {
        maxSize: rules.maxFileSizeInBytes.constraintPretty,
      }),
    ];
  });

  protected videoRules = computed<RuleList>(() => {
    const rules = getVideoField("video", this.fields()).rules;

    return [
      new RuleItemVM(
        "category.aspectRatio",
        "facebookCarousel.video.aspectRatio",
        {
          aspectRatio: rules.aspectRatio.constraintPretty,
        },
      ),
      new RuleItemVM("category.width", "facebookCarousel.video.width", {
        min: rules.minWidth.constraint,
        max: rules.maxWidth.constraint,
      }),
      new RuleItemVM("category.height", "facebookCarousel.video.height", {
        min: rules.minHeight.constraint,
        max: rules.maxHeight.constraint,
      }),
      new RuleItemVM("category.format", "facebookCarousel.video.format", {
        format: rules.allowedMimeTypes.constraintPretty,
      }),
      new RuleItemVM("category.fileSize", "facebookCarousel.video.fileSize", {
        maxSize: rules.maxFileSizeInBytes.constraintPretty,
      }),
      new RuleItemVM("category.duration", "facebookCarousel.video.duration", {
        max: rules.maxVideoDurationInSeconds.constraint,
      }),
    ];
  });

  protected toggle(): void {
    this.isOpen.set(!this.isOpen());
  }

  protected toggleDetails(): void {
    this.isDetailsOpen.set(!this.isDetailsOpen());
  }
}
