import { PublicationTemplateStatus } from "../../../brand/brand-campaign/publication-template/domain/models/publication-template";
import { SelectorOption } from "../../../shared/components/filtering/filter.interfaces";
import { TextCaseTransform } from "../../../shared/functions/text-case-transformations";
import { Page } from "../../shared/domain/models/page";

export class GetPublicationTemplateStatusOptionsInteractor {
  public async execute(): Promise<Page<SelectorOption>> {
    const types = Object.values(PublicationTemplateStatus);
    return {
      pageNumber: 0,
      size: types.length,
      totalSize: types.length,
      entries: types.map((type) => ({
        id: type,
        name: `publicationTemplateStatus.${TextCaseTransform.snakeToCamel(
          type,
        )}`,
      })),
    };
  }
}
