import { NotificationService } from "../../../../../shared/services/notification.service";
import { PublicationTemplateEntity } from "../../data/entities/publication-templates.entity";
import { PublicationTemplateService } from "../../data/publication-template.service";
import { PublicationTemplate } from "../models/publication-template";

export class UpdatePublicationTemplateInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
    private readonly notificationService: NotificationService,
  ) {}

  public async execute(
    publicationTemplate: PublicationTemplate,
    data: PublicationTemplateEntity,
  ): Promise<PublicationTemplate> {
    try {
      return await this.publicationTemplateService.update(
        publicationTemplate,
        data,
      );
    } catch (error) {
      this.notificationService.error("publicationTemplate.update.error");
      throw error;
    }
  }
}
