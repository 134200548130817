import { Component, inject } from "@angular/core";
import { BrandService } from "../../../../../shared/services/api/brand.service";
import { BrandUrl } from "../../../../brand.url";
import { BrandCampaignService } from "../../../shared/services/brand-campaign.service";
import { PublicationTemplateService } from "../../data/publication-template.service";
import {
  PublicationTemplatesByPlatform,
  PublicationTemplateTypesVm,
} from "./publication-template-types.vm";
import { PUBLICATION_TEMPLATES_MAP } from "../../publication-templates/publication-templates.config";

@Component({
  selector: "app-publication-template-types",
  templateUrl: "./publication-template-types.component.html",
  styleUrls: ["./publication-template-types.component.scss"],
})
export class PublicationTemplateTypesComponent {
  protected readonly BrandUrl = BrandUrl;
  protected readonly brandId = inject(BrandService).currentBrandId;
  protected vm: PublicationTemplatesByPlatform[] = [];
  protected readonly campaignId =
    inject(BrandCampaignService).currentBrandCampaignId;
  // temporary constant that will be removed once all the types are implemented
  protected readonly PUBLICATION_TEMPLATES_MAP = PUBLICATION_TEMPLATES_MAP;
  private readonly availablePublicationTypes = inject(
    PublicationTemplateService,
  ).getAvailablePublicationTypes();

  constructor() {
    this.vm = PublicationTemplateTypesVm(this.availablePublicationTypes);
  }
}
