import {
  Component,
  computed,
  input,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatIconButton } from "@angular/material/button";
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { LinkType } from "../../domain/models/publication-template";

export interface PublicationTemplateLinkForm {
  link: FormControl<string | null>;
  linkType: FormControl<LinkType>;
}

@Component({
  standalone: true,
  selector: "app-publication-template-link-selector",
  templateUrl: "./publication-template-link-selector.component.html",
  styleUrl: "./publication-template-link-selector.component.scss",
  imports: [
    MatError,
    MatHint,
    ReactiveFormsModule,
    MatLabel,
    MatFormField,
    TranslateModule,
    MatInput,
    MatIconButton,
    MatIcon,
  ],
})
export class PublicationTemplateLinkSelectorComponent
  implements OnInit, OnDestroy
{
  protected readonly LinkType = LinkType;
  protected readonly inputFocused = signal(false);
  protected readonly form =
    input.required<FormGroup<PublicationTemplateLinkForm>>();
  protected readonly maxLength = input.required<number>();

  protected readonly link = computed(
    () => this.form().get("link") as FormControl<string | null>,
  );
  protected readonly linkType = computed(
    () => this.form().get("linkType") as FormControl<LinkType>,
  );
  protected readonly isSelectorOpen = signal(false);
  protected readonly LinkTypes: LinkType[] = Object.values(LinkType);
  private readonly subs = new Subscription();

  public ngOnInit(): void {
    this.subs.add(
      this.linkType().valueChanges.subscribe((value) => {
        if (value === LinkType.CustomUrl) {
          this.link().addValidators(Validators.required);
        } else {
          this.link().setValue(null);
          this.link().removeValidators(Validators.required);
        }

        this.link().updateValueAndValidity();
        this.link().markAsPristine();
        this.link().markAsUntouched();

        this.form().controls.link = this.link();
        this.form().controls.linkType = this.linkType();

        this.form().markAsDirty();
        this.form().updateValueAndValidity();
      }),
    );

    this.subs.add(
      this.link().valueChanges.subscribe((url) => {
        this.form().controls.link.setValue(url, { emitEvent: false });
        this.form().markAsDirty();
        this.form().updateValueAndValidity();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected toggleMenu(event: Event): void {
    event.preventDefault();
    this.isSelectorOpen.update((isOpen) => !isOpen);
  }

  protected onActionOptionSelected(option: LinkType): void {
    this.isSelectorOpen.set(false);
    this.linkType().setValue(option);
  }
}
