<div #container [ngClass]="{ extra: isExtraSlide(), slide: true }">
  @defer (on viewport(container)) {
    @if (slide(); as item) {
      <img class="image" [src]="item.media.link" loading="lazy" />
    } @else {
      <div class="placeholder-text">
        <span>Upload Content to Media Files for previewing</span>
      </div>
    }
  }
  <div class="text">
    <span class="title">
      {{ (slide() ? slide()?.title : "Image headline") | translate }}
    </span>
    <span class="description">
      {{ (slide() ? slide()?.description : "Image description") | translate }}
    </span>
  </div>
</div>
