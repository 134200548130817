import { z } from "zod";

export enum MediaType {
  Image = "image",
  Video = "video",
  Visual = "visual",
}

const BaseMediaParser = z
  .object({
    id: z.string().uuid(),
    link: z.string().url(),
  })
  .strict();

const VisualMediaParser = BaseMediaParser.extend({
  mediaType: z.literal(MediaType.Visual),
  height: z.number().min(1),
  thumbnailLink: z.string().url(),
  width: z.number().min(1),
});

const ImageMediaParser = VisualMediaParser.extend({
  mediaType: z.literal(MediaType.Image),
  colors: z.string().nullable(),
  isSRGB: z.boolean().nullable(),
});

export type ImageMediaEntity = z.infer<typeof ImageMediaParser>;

const VideoMediaParser = VisualMediaParser.extend({
  mediaType: z.literal(MediaType.Video),
  lengthInSeconds: z.number().min(1).nullable(),
  frameRate: z.number().min(1).nullable(),
  videoDecoder: z.string().nullable(),
  videoBitrate: z.number().min(1).nullable(),
  audioDecoder: z.string().nullable(),
  audioBitrate: z.number().min(1).nullable(),
  audioSamplingRate: z.number().min(1).nullable(),
  audioNumOfChannels: z.number().min(0).nullable(),
});

export type VideoMediaEntity = z.infer<typeof VideoMediaParser>;

export const MediaParser = z.discriminatedUnion("mediaType", [
  ImageMediaParser,
  VideoMediaParser,
]);

export type MediaEntity = z.infer<typeof MediaParser>;
