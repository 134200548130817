<section class="header">
  <div class="placeholders">
    <div class="avatar-ph"></div>
    <div class="texts">
      <div class="text-ph text-ph--short"></div>
      <div class="text-ph"></div>
    </div>
  </div>
  <span class="text">{{ data.text }}</span>
</section>
<section class="carousel-wrapper" #carouselWrapper>
  <div class="carousel-inner" #carousel>
    @for (item of data.slides; track item; let i = $index) {
      <app-facebook-carousel-slide-preview
        [slide]="item"
        class="carousel-item"
        (click)="goToSlide(i)"
        #slide
        [ngClass]="{ current: i === currentSlide() }"
      />
    } @empty {
      <app-facebook-carousel-slide-preview
        class="carousel-item"
        (click)="goToSlide(0)"
        #slide
        [ngClass]="{ current: 0 === currentSlide() }"
      />
      <app-facebook-carousel-slide-preview
        class="carousel-item"
        (click)="goToSlide(1)"
        #slide
        [ngClass]="{ current: 1 === currentSlide() }"
      />
    }
    <app-facebook-carousel-slide-preview
      class="carousel-item"
      (click)="goToSlide(data.slides?.length ? data.slides?.length : 2)"
      [slide]="extraSlide"
      [isExtraSlide]="true"
      #slide
      [ngClass]="{
        current:
          data.slides?.length === currentSlide() ||
          (!data.slides && 2 === currentSlide())
      }"
    />
  </div>
  <div class="buttons">
    @if (currentSlide() > 0) {
      <button (click)="prev()" class="button button-prev">
        <mat-icon>chevron_left</mat-icon>
      </button>
    }
    @if (
      (!data.slides?.length && currentSlide() < 2) ||
      (data.slides?.length && currentSlide() < data.slides?.length)
    ) {
      <button (click)="next()" class="button button-next">
        <mat-icon>chevron_right</mat-icon>
      </button>
    }
  </div>
</section>
<section class="footer">
  <div class="circle"></div>
  <div class="line"></div>
</section>
