import { LowerCasePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { PublicationEventInput } from "./publication-event-input";

@Component({
  standalone: true,
  selector: "app-calendar-event-publication",
  templateUrl: "./calendar-event-publication.component.html",
  styleUrl: "./calendar-event-publication.component.scss",
  imports: [LowerCasePipe, TranslateModule],
})
export class CalendarEventPublicationComponent {
  @Input({ required: true }) event!: PublicationEventInput;
}
