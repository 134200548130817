import { Directive } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PublicationTemplateType } from "../../data/entities/publication-templates.entity";
import { PublicationTemplateFields } from "../../data/entities/publication-template-fields.entity";
import { FormGroupType } from "../../../../../../harmony/angular";
import { PublicationTemplate } from "../../domain/models/publication-template";

@Directive()
export abstract class PublicationTemplateForm<
  Form extends FormGroupType<Form>,
> {
  public fields!: PublicationTemplateFields;
  public form!: FormGroup<Form>;

  protected constructor(protected readonly type: PublicationTemplateType) {}
  public abstract createForm(): void;
  public abstract setFormValues(values: PublicationTemplate): void;
}
