<div
  class="file-input"
  (click)="fileInput.click()"
  [ngClass]="{ disabled: isDisabled, hovering: isHovering }"
  ngFileSelect
  ngFileDrop
  (uploadOutput)="onUploadOutput($event)"
>
  <mat-icon class="icon">image</mat-icon>
  <span [innerHTML]="'shared.dragAndDropMedia' | translate"></span>
  <input
    type="file"
    #fileInput
    [multiple]="multiple"
    (input)="onActionFileAdded($event)"
    [disabled]="isDisabled"
    [attr.accept]="accept ? accept.join(',') : null"
  />
</div>
