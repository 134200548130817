import { Component, OnDestroy } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { ImageMedia } from "../../../../../../features/media/domain/models/media";
import { CustomFormValidators } from "../../../../../../shared/validators/custom-form-validators";
import { PublicationTemplateForm } from "../../../components/publication-template-form/publication-template-form";
import {
  FacebookCarouselFields,
  getField,
} from "../../../data/entities/publication-template-fields.entity";
import { PublicationTemplateType } from "../../../data/entities/publication-templates.entity";
import {
  FacebookCarousel,
  LinkType,
} from "../../../domain/models/publication-template";
import { FacebookCarouselSlideForm } from "./facebook-carousel-slide-form/facebook-carousel-slide-form.component";

export interface FacebookCarouselForm {
  slides: FormArray<FormGroup<FacebookCarouselSlideForm>>;
  text: FormControl<string | null>;
  media: FormControl<ImageMedia[] | null>;
  linkType: FormControl<LinkType>;
  link: FormControl<string | null>;
}

@Component({
  selector: "app-facebook-carousel-form",
  templateUrl: "./facebook-carousel-form.component.html",
  styleUrls: [
    "../../../components/publication-template-form/publication-template-form.scss",
    "./facebook-carousel-form.component.scss",
  ],
})
export class FacebookCarouselFormComponent
  extends PublicationTemplateForm<FacebookCarouselForm>
  implements OnDestroy
{
  protected isTextFocused = false;
  public fields!: FacebookCarouselFields;
  protected maxTextLength?: number;
  protected maxSlideTitleLength?: number;
  protected maxSlideDescriptionLength?: number;
  protected maxSlideLinkLength?: number;
  protected slideLinkRegex?: string;
  protected minNumSlides = 2;
  protected maxNumSlides = 10;
  private readonly subs = new Subscription();
  private mandatoryUrlRules: ValidatorFn[] = [];
  private optionalUrlRules: ValidatorFn[] = [];

  constructor() {
    super(PublicationTemplateType.FacebookCarousel);
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public createForm(): void {
    this.maxTextLength = getField(
      "text",
      this.fields,
    ).rules.maxTextLength.constraint;
    this.maxSlideLinkLength = getField(
      "landingPageUrl",
      this.fields,
    ).rules.maxTextLength.constraint;
    this.maxSlideTitleLength = getField(
      "mediaTitle",
      this.fields,
    ).rules.maxTextLength.constraint;
    this.maxSlideDescriptionLength = getField(
      "mediaDescription",
      this.fields,
    ).rules.maxTextLength.constraint;
    this.minNumSlides = getField(
      "slides",
      this.fields,
    ).rules.minSlidesSize.constraint;
    this.maxNumSlides = getField(
      "slides",
      this.fields,
    ).rules.maxSlidesSize.constraint;
    this.slideLinkRegex = getField(
      "landingPageUrl",
      this.fields,
    ).rules.landingPageUrlRegex.constraint;

    this.optionalUrlRules = [
      Validators.maxLength(this.maxSlideLinkLength!),
      CustomFormValidators.pattern(this.slideLinkRegex!),
    ];

    this.mandatoryUrlRules = [Validators.required, ...this.optionalUrlRules];

    this.form = new FormGroup<FacebookCarouselForm>({
      slides: new FormArray<FormGroup<FacebookCarouselSlideForm>>(
        [],
        [
          CustomFormValidators.minElementsValidator(this.minNumSlides),
          CustomFormValidators.maxElementsValidator(this.maxNumSlides),
        ],
      ),
      text: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.maxLength(this.maxTextLength!),
        ],
        nonNullable: true,
      }),
      media: new FormControl([] as ImageMedia[]),
      linkType: new FormControl(LinkType.PublisherUrl, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      link: new FormControl(null, {
        validators: this.optionalUrlRules,
      }),
    });

    this.form.controls.media.valueChanges.subscribe((medias) => {
      if (!medias?.length) {
        return;
      }
      medias.map((media) => this.addSlide(media));
      this.form.controls.media.patchValue([], { emitEvent: false });
    });
  }

  public setFormValues(facebookCarousel: FacebookCarousel): void {
    this.form.controls.text.patchValue(facebookCarousel.text);
    this.form.controls.linkType.patchValue(
      facebookCarousel.linkType ?? LinkType.PublisherUrl,
    );
    this.form.controls.link = new FormControl<string | null>(
      facebookCarousel.link ?? null,
      {
        validators:
          facebookCarousel.linkType === LinkType.CustomUrl
            ? this.mandatoryUrlRules
            : this.optionalUrlRules,
      },
    );
    const slidesArray = new FormArray(
      facebookCarousel.slides.map(
        (slide) =>
          new FormGroup<FacebookCarouselSlideForm>({
            media: new FormControl<ImageMedia>(slide.media, {
              validators: [Validators.required],
              nonNullable: true,
            }),
            title: new FormControl<string>(slide.title, {
              validators: [
                Validators.required,
                Validators.maxLength(this.maxSlideTitleLength!),
              ],
              nonNullable: true,
            }),
            description: new FormControl(
              slide.description ?? LinkType.PublisherUrl,
              [Validators.maxLength(this.maxSlideDescriptionLength!)],
            ),
            linkType: new FormControl<LinkType>(slide.linkType, {
              validators: [Validators.required],
              nonNullable: true,
            }),
            link: new FormControl<string | null>(slide.link ?? null, {
              validators:
                slide.linkType === LinkType.CustomUrl
                  ? this.mandatoryUrlRules
                  : this.optionalUrlRules,
            }),
          }),
      ),
      [
        CustomFormValidators.minElementsValidator(this.minNumSlides),
        CustomFormValidators.maxElementsValidator(this.maxNumSlides),
      ],
    );

    this.form.setControl("slides", slidesArray);
  }

  private addSlide(media: ImageMedia): void {
    const slideForm = new FormGroup<FacebookCarouselSlideForm>({
      media: new FormControl(media, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      title: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.maxLength(this.maxSlideTitleLength!),
        ],
        nonNullable: true,
      }),
      description: new FormControl(
        null,
        Validators.maxLength(this.maxSlideDescriptionLength!),
      ),
      linkType: new FormControl(LinkType.PublisherUrl, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      link: new FormControl(null, {
        validators: this.optionalUrlRules,
      }),
    });
    this.form.controls.slides.push(slideForm);
  }

  protected removeSlide(index: number): void {
    this.form.controls.slides.removeAt(index);
  }

  protected onActionSlidesReordered(event: any): void {
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    const slideToMove = this.form.controls.slides.at(event.previousIndex);
    this.removeSlide(event.previousIndex);
    this.form.controls.slides.insert(event.currentIndex, slideToMove);
    this.form.markAsTouched();
    this.form.markAsDirty();
  }
}
