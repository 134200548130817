import {
  CalendarOptions,
  EventClickArg,
  EventDropArg,
  EventInput,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { TranslateService } from "@ngx-translate/core";
import { BrandCampaign } from "../../../../../shared/models/brandCampaign";
import "../../../../../shared/extensions/date.extensions";
import { PublicationTemplate } from "../../domain/models/publication-template";

export const PublicationTemplateScheduleCalendarConfig = (
  campaign: BrandCampaign,
  publicationTemplate: PublicationTemplate,
  onEventClick: (args: EventClickArg) => void,
  onEventDrop: (args: EventDropArg) => void,
  translateService: TranslateService,
): CalendarOptions => ({
  initialView: "dayGridMonth",
  dayMaxEventRows: 10,
  dayMaxEvents: 10,
  plugins: [dayGridPlugin, interactionPlugin],
  headerToolbar: {
    left: "today prev,next title",
    center: "",
    right: "",
  },
  buttonText: {
    today: translateService.instant("shared.today"),
  },
  firstDay: 1,
  droppable: false,
  weekends: true,
  editable: true,
  selectable: true,
  eventOrder: ((a: EventInput, b: EventInput): number => {
    const da = new Date(a.start as string);
    const db = new Date(b.start as string);
    if (
      da.getFullYear() === db.getFullYear() &&
      da.getMonth() === db.getMonth() &&
      da.getDate() === db.getDate()
    ) {
      if (a.extendedProps?.publicationTemplateId === publicationTemplate?.id) {
        return -1;
      } else if (
        b.extendedProps?.publicationTemplateId === publicationTemplate?.id
      ) {
        return 1;
      }
    }

    return da < db ? 1 : -1;
  }) as any,
  selectMirror: true,
  validRange: {
    start: campaign.startDate,
    end: campaign.endDate,
  },
  eventClick: onEventClick,
  eventDrop: onEventDrop,
  dayCellClassNames: (args) => {
    const classes = ["sp-day"];
    if (args.date.isInDayRange(campaign) && args.date.isTodayOrFutureDay()) {
      classes.push("sp-day--inside-range");
    }

    return classes.join(" ");
  },
});
