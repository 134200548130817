import { Page } from "../../../../../features/shared/domain/models/page";
import { PublicationTemplateService } from "../../data/publication-template.service";
import { PublicationTemplate } from "../models/publication-template";

export class GetPublicationTemplatesInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
  ) {}

  public async execute(campaignId: number): Promise<Page<PublicationTemplate>> {
    return this.publicationTemplateService.getAllByCampaign(campaignId);
  }
}
