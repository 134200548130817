@if (linkType().value === LinkType.CustomUrl) {
  <mat-form-field class="field" floatLabel="always">
    <mat-label>Link</mat-label>
    <input
      matInput
      [formControl]="link()"
      placeholder="Custom URL"
      (focus)="inputFocused.set(true)"
      (blur)="inputFocused.set(false)"
    />
    @if (inputFocused()) {
      <mat-hint align="end">
        {{ link().value?.length ?? 0 }} Characters ({{ maxLength() }}
        recommended)
      </mat-hint>
    }
    @if (link().touched && link().errors?.required) {
      <mat-error>{{ "shared.requiredField" | translate }}</mat-error>
    }
    @if (link().touched && link().errors?.pattern) {
      <mat-error>{{ "shared.enterValidUrl" | translate }}</mat-error>
    }
    @if (link().touched && link().errors?.maxlength) {
      <mat-error>
        {{ "Max length: " + maxLength() + " characters" | translate }}
      </mat-error>
    }
  </mat-form-field>
} @else {
  <div class="url-description">
    {{ "publicationTemplate.carousel.link." + linkType().value | translate }}
  </div>
}
<div class="selector">
  <button mat-icon-button (click)="toggleMenu($event)">
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  @if (isSelectorOpen()) {
    <div class="list">
      @for (type of LinkTypes; track type) {
        <button (click)="onActionOptionSelected(type)">
          {{ "publicationTemplate.carousel.link." + type | translate }}
        </button>
      }
    </div>
  }
</div>
