import { Mapper } from "../../../../../harmony/core";
import { MediaEntity, MediaType } from "../../data/entities/media.entity";
import { ImageMedia, Media, VideoMedia } from "../models/media";

export class MediaEntityToMediaMapper implements Mapper<MediaEntity, Media> {
  public map(from: MediaEntity): Media {
    switch (from.mediaType) {
      case MediaType.Image:
        return new ImageMedia(
          from.id,
          from.width,
          from.height,
          from.link,
          from.thumbnailLink,
          from.colors ?? undefined,
          from.isSRGB ?? undefined,
        );

      case MediaType.Video:
        return new VideoMedia(
          from.id,
          from.width,
          from.height,
          from.link,
          from.thumbnailLink,
          from.lengthInSeconds ?? undefined,
          from.frameRate ?? undefined,
          from.audioBitrate ?? undefined,
          from.audioDecoder ?? undefined,
          from.audioNumOfChannels ?? undefined,
          from.audioSamplingRate ?? undefined,
          from.videoBitrate ?? undefined,
          from.videoDecoder ?? undefined,
        );

      default:
        throw new Error("Unknown media type");
    }
  }
}
