<div class="page-header">
  <div class="left-content">
    @if (backLink) {
      <a class="back-button" [routerLink]="backLink">
        <mat-icon>arrow_back</mat-icon>
      </a>
    }
    <h1>{{ title | translate }}</h1>
  </div>
  <ng-content />
</div>
