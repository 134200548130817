import { Injectable } from "@angular/core";
import { CommonDialogService } from "../../../shared/services/common-dialog.service";
import {
  SchedulePublicationTemplateDialogComponent,
  SchedulePublicationTemplateDialogData,
  SchedulePublicationTemplateDialogResult,
} from "./components/schedule-publication-template-dialog/schedule-publication-template-dialog.component";

@Injectable()
export class PublicationTemplateDialogService {
  constructor(private dialog: CommonDialogService) {}

  public readonly showSchedulePublicationTemplate =
    this.dialog.createOpenDialogFn<
      SchedulePublicationTemplateDialogData,
      SchedulePublicationTemplateDialogResult
    >(SchedulePublicationTemplateDialogComponent, {
      disableClose: true,
    });
}
