import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import "../../../../../shared/extensions/date.extensions";
import { ActivatedRoute, Router } from "@angular/router";
import { FullCalendarComponent } from "@fullcalendar/angular";
import {
  CalendarOptions,
  EventClickArg,
  EventDropArg,
} from "@fullcalendar/core";
import { TranslateService } from "@ngx-translate/core";
import { BrandCampaign } from "../../../../../shared/models/brandCampaign";
import { BrandService } from "../../../../../shared/services/api/brand.service";
import { cloneWith } from "../../../../../shared/services/clonable";
import { NotificationService } from "../../../../../shared/services/notification.service";
import { assert } from "../../../../../shared/utils/assert";
import { BrandCampaignService } from "../../../shared/services/brand-campaign.service";
import { GetPublicationTemplateInteractor } from "../../domain/interactors/get-publication-template.interactor";
import { GetPublicationTemplatesInteractor } from "../../domain/interactors/get-publication-templates.interactor";
import {
  PublicationTemplate,
  RecommendedSchedule,
} from "../../domain/models/publication-template";
import { PublicationTemplateDialogService } from "../../publication-template-dialog.service";
import { PublicationTemplateCalendarEventFactory } from "./calendar-event-publication/calendar-event.mappers";
import { BrandUrl } from "../../../../brand.url";
import { PublicationEventInput } from "./calendar-event-publication/publication-event-input";
import { PublicationTemplateScheduleCalendarConfig } from "./publication-template-schedule-calendar.config";

@Component({
  selector: "app-publication-template-schedule",
  templateUrl: "./publication-template-schedule.component.html",
  styleUrl: "./publication-template-schedule.component.scss",
})
export class PublicationTemplateScheduleComponent
  implements OnInit, AfterViewInit
{
  protected readonly BrandUrl = BrandUrl;
  protected readonly brandId = inject(BrandService).currentBrandId;
  protected readonly campaignId: number;
  protected publicationTemplate?: PublicationTemplate;
  protected campaign?: BrandCampaign;
  protected calendarConfig?: CalendarOptions;
  private publicationTemplates: PublicationTemplate[] = [];
  private calendar?: FullCalendarComponent;
  @ViewChild("calendarWrapper", { static: true })
  private calendarWrapper?: ElementRef;
  @ViewChild("previewWrapper", { static: true })
  private previewWrapper?: ElementRef;

  @ViewChild("calendar") set fullCalendar(calendar: FullCalendarComponent) {
    this.calendar = calendar;
    this.loadCalendarEvents();
  }

  constructor(
    private readonly getPublicationTemplate: GetPublicationTemplateInteractor,
    private readonly getPublicationTemplates: GetPublicationTemplatesInteractor,
    private readonly publicationTemplateEventFactory: PublicationTemplateCalendarEventFactory,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialogService: PublicationTemplateDialogService,
    private readonly brandCampaignService: BrandCampaignService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
  ) {
    this.campaignId = Number(this.route.snapshot.params.campaignId);
  }

  public async ngOnInit(): Promise<void> {
    try {
      this.campaign = this.brandCampaignService.currentBrandCampaign;
    } catch (e) {
      this.campaign = await this.brandCampaignService.loadBrandCampaign(
        this.campaignId,
      );
    }

    await this.loadPublicationTemplates();
    await this.loadCalendarConfig();
    await this.loadCalendarEvents();

    this.route.queryParams.subscribe((params) => {
      if (params.schedule) {
        this.onEditSchedule();
      }
    });
  }

  public ngAfterViewInit(): void {
    this.adjustListHeight();
    const observer = new ResizeObserver(() => {
      this.adjustListHeight();
    });
    observer.observe(this.calendarWrapper?.nativeElement);
  }

  private async loadPublicationTemplates(): Promise<void> {
    const publicationTemplateId =
      this.route.snapshot.params.publicationTemplateId;

    try {
      await Promise.all([
        this.getPublicationTemplate.execute(publicationTemplateId),
        this.getPublicationTemplates.execute(this.campaignId),
      ]).then(([publicationTemplate, publicationTemplates]) => {
        this.publicationTemplate = publicationTemplate;
        this.publicationTemplates = publicationTemplates.entries;
      });
    } catch (e) {
      console.error(e);
      await this.router.navigate(["/not-found"]);
    }
  }

  private async onEventDrop(args: EventDropArg): Promise<void> {
    assert(this.campaign);
    assert(args.event.start);

    if (
      !args.event.start.isInDayRange(this.campaign) ||
      !args.event.start.isTodayOrFutureDay()
    ) {
      this.notificationService.error(
        "publicationTemplate.update.error.outsideRange",
      );
      await this.loadCalendarEvents();
      return;
    }

    const dateInLocalTime = new Date(
      args.event.start.getTime() - args.event.start.getTimezoneOffset() * 60000,
    );

    await this.onEditSchedule(dateInLocalTime, args.event.id);
  }

  private async onEventClick(args: EventClickArg): Promise<void> {
    if (
      args.event.extendedProps.publicationTemplateId !==
      this.publicationTemplate?.id
    ) {
      return;
    }

    if (
      new Date(args.event.start as any as string).isTodayOrFutureDay() === false
    ) {
      return;
    }
    await this.onEditSchedule(undefined, args.event.id);
  }

  private async loadCalendarConfig(): Promise<void> {
    if (!this.campaign || !this.publicationTemplate) {
      return;
    }

    this.calendarConfig = PublicationTemplateScheduleCalendarConfig(
      this.campaign,
      this.publicationTemplate,
      this.onEventClick.bind(this),
      this.onEventDrop.bind(this),
      this.translateService,
    );
  }

  private async loadCalendarEvents(): Promise<void> {
    if (!this.calendar) {
      return;
    }

    const campaign = this.campaign;
    const publicationTemplate = this.publicationTemplate;
    assert(publicationTemplate);
    assert(campaign);

    this.calendar.events = this.publicationTemplates
      .flatMap((pt) =>
        this.publicationTemplateEventFactory.create(pt, campaign),
      )
      .map((event): PublicationEventInput => {
        const isCurrentPublication =
          event.extendedProps.publicationTemplateId ===
          `${publicationTemplate.id}`;

        return {
          ...event,
          disabled: !isCurrentPublication,
          editable:
            isCurrentPublication &&
            new Date(event.start as string).isTodayOrFutureDay(),
        };
      });
  }

  protected async onSchedulesUpdated(
    schedules: RecommendedSchedule[],
  ): Promise<void> {
    assert(this.publicationTemplate);
    this.publicationTemplate = cloneWith(this.publicationTemplate, {
      recommendedSchedules: schedules,
    });

    this.publicationTemplates = this.publicationTemplates.map((publication) =>
      publication.id === this.publicationTemplate?.id
        ? this.publicationTemplate
        : publication,
    );
    await this.loadCalendarEvents();
  }

  protected async onSchedulePublication(date: Date): Promise<void> {
    await this.onEditSchedule(date);
  }

  protected async onEditSchedule(
    date?: Date,
    recommendedScheduleId?: string,
  ): Promise<void> {
    assert(this.publicationTemplate);
    assert(this.campaign);

    const result = await this.dialogService.showSchedulePublicationTemplate({
      campaign: this.campaign,
      publicationTemplateId: this.publicationTemplate.id,
      date: date,
      schedules: this.publicationTemplate.recommendedSchedules,
      scheduleId: recommendedScheduleId,
    });

    if (!result) {
      await this.loadCalendarEvents();
      return;
    }

    await this.onSchedulesUpdated(result.schedules);
  }

  public adjustListHeight(): void {
    if (!this.calendarWrapper || !this.previewWrapper) {
      return;
    }
    const calendarHeight = this.calendarWrapper.nativeElement.offsetHeight;
    this.previewWrapper.nativeElement.style.maxHeight = `${calendarHeight}px`;
  }
}
