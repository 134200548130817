<div
  (mouseenter)="areOptionsShown.set(true)"
  (mouseleave)="areOptionsShown.set(false)"
>
  <button [ngClass]="{ active: areOptionsShown() }">
    <span class="type">
      <mat-icon>{{ FilterIcon[type()] }}</mat-icon>
      {{ "filter." + type() | translate }}
    </span>
    <mat-icon>chevron_right</mat-icon>
  </button>

  @if (areOptionsShown()) {
    <div class="popover">
      <div class="visible-area">
        @if (canSearch()) {
          <div class="search">
            <mat-form-field subscriptSizing="dynamic">
              <input type="text" matInput [formControl]="searchControl" />
              <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
          </div>
        }
        @if (options()?.length) {
          <div class="options">
            @for (option of options(); track option.id) {
              <div class="option" (click)="onActionOptionSelected(option)">
                {{ option.name | translate }}
              </div>
            }
          </div>
        }
        @if (!areAllOptionsLoaded()) {
          @if (!isLoading()) {
            <app-infinite-scroll-anchor
              [isLoading]="isLoading()"
              (intersecting)="onOptionsScrolled()"
            />
          }
          <div class="loading">{{ "shared.loading" | translate }}</div>
        }
      </div>
    </div>
  }
</div>
