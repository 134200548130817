export interface SelectorOption {
  readonly id: number | string | Date | undefined;
  readonly name: string;
}

export enum FilterType {
  Creator = "creatorIds",
  Campaign = "campaignIds",
  Publisher = "publisherIds",
  TimePeriod = "timePeriod",
  PublicationTemplateStatus = "status",
  PublicationTemplateType = "contentTypes",
}

export const FilterIcon = {
  [FilterType.Creator]: "business_center",
  [FilterType.Campaign]: "campaign",
  [FilterType.Publisher]: "people",
  [FilterType.TimePeriod]: "calendar_today",
  [FilterType.PublicationTemplateStatus]: "flag",
  [FilterType.PublicationTemplateType]: "view_carousel",
};

export const isSearchableFilter = (type: FilterType): boolean => {
  switch (type) {
    case FilterType.PublicationTemplateStatus:
    case FilterType.PublicationTemplateType:
    case FilterType.TimePeriod:
      return false;
    default:
      return true;
  }
};

export interface FilterOutput {
  readonly value: SelectorOption;
  readonly type: FilterType;
}
