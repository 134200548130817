import { DatePipe, NgClass, NgStyle } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { BrandCampaign } from "../../../../../shared/models/brandCampaign";
import { SharedPipesModule } from "../../../../../shared/pipes/shared-pipes.module";
import { assert } from "../../../../../shared/utils/assert";
import { RecommendedSchedule } from "../../domain/models/publication-template";
import { PublicationTemplateDialogService } from "../../publication-template-dialog.service";

@Component({
  standalone: true,
  selector: "app-publication-template-schedules",
  templateUrl: "./publication-template-schedules.component.html",
  styleUrl: "./publication-template-schedules.component.scss",
  imports: [
    TranslateModule,
    MatIconModule,
    SharedPipesModule,
    DatePipe,
    NgClass,
    NgStyle,
  ],
})
export class PublicationTemplateSchedulesComponent {
  @Output() public readonly goToScheduleWithEdition =
    new EventEmitter<boolean>();
  @Output() public readonly schedulesUpdated = new EventEmitter<
    RecommendedSchedule[]
  >();
  @Input({ required: true }) public campaign!: BrandCampaign;
  @Input() public mode: "show" | "edit" = "show";
  @Input() public publicationTemplateId?: string;
  @Input() public set schedules(schedules: RecommendedSchedule[]) {
    this.sortedSchedules = schedules
      ? schedules.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
      : [];
  }

  protected sortedSchedules: RecommendedSchedule[] = [];

  constructor(
    private readonly dialogService: PublicationTemplateDialogService,
  ) {}

  protected async onEditSchedule(
    recommendedSchedule?: RecommendedSchedule,
  ): Promise<void> {
    assert(this.publicationTemplateId);
    assert(this.campaign);

    const result = await this.dialogService.showSchedulePublicationTemplate({
      campaign: this.campaign,
      publicationTemplateId: this.publicationTemplateId,
      scheduleId: recommendedSchedule?.id,
      schedules: this.sortedSchedules,
    });

    if (!result) {
      return;
    }

    this.sortedSchedules = result.schedules;
    this.schedulesUpdated.emit(this.sortedSchedules);
  }
}
