import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  standalone: true,
  selector: "app-calendar-day-cell",
  templateUrl: "./calendar-day-cell.component.html",
  styleUrl: "./calendar-day-cell.component.scss",
})
export class CalendarDayCellComponent {
  @Input({ required: true }) dayNumberText!: string;
  @Input({ required: true }) date!: Date;
  @Output() add = new EventEmitter<Date>();
}
