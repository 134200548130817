import {
  Component,
  computed,
  EventEmitter,
  input,
  Input,
  Output,
  signal,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import {
  ImageMedia,
  Media,
} from "../../../../../../../features/media/domain/models/media";
import { MediaPreviewComponent } from "../../../../../../../shared/components/media-preview/media-preview.component";
import {
  FacebookCarouselFields,
  getField,
} from "../../../../data/entities/publication-template-fields.entity";
import { LinkType } from "../../../../domain/models/publication-template";
import { PublicationTemplateLinkSelectorComponent } from "../../../publication-template-link-selector/publication-template-link-selector.component";

export interface FacebookCarouselSlideForm {
  media: FormControl<ImageMedia>;
  title: FormControl<string | null>;
  description: FormControl<string | null>;
  linkType: FormControl<LinkType>;
  link: FormControl<string | null>;
}

@Component({
  standalone: true,
  selector: "app-facebook-carousel-slide-form",
  templateUrl: "./facebook-carousel-slide-form.component.html",
  styleUrl: "./facebook-carousel-slide-form.component.scss",
  imports: [
    MediaPreviewComponent,
    MatFormField,
    MatInput,
    MatHint,
    MatLabel,
    ReactiveFormsModule,
    MatIcon,
    MatError,
    TranslateModule,
    PublicationTemplateLinkSelectorComponent,
  ],
})
export class FacebookCarouselSlideFormComponent {
  @Output() remove = new EventEmitter<void>();
  @Input({ required: true }) slideForm!: FormGroup<FacebookCarouselSlideForm>;
  @Input({ required: true }) media!: Media;
  public readonly fields = input.required<FacebookCarouselFields>();
  protected readonly maxTitleLength = computed<number>(
    () => getField("mediaTitle", this.fields()).rules.maxTextLength.constraint,
  );
  protected readonly maxDescriptionLength = computed<number>(
    () =>
      getField("mediaDescription", this.fields()).rules.maxTextLength
        .constraint,
  );
  protected readonly maxLinkLength = computed<number>(
    () =>
      getField("landingPageUrl", this.fields()).rules.maxTextLength.constraint,
  );
  protected readonly inputFocused = signal<
    "link" | "title" | "description" | undefined
  >(undefined);
}
