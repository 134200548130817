import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { z } from "zod";
import { BlankMapper } from "../../../../harmony/core";
import { createPageParser } from "../../../features/shared/data/page.parser";
import { Page } from "../../../features/shared/domain/models/page";
import { StrictApiRequestService } from "../../http/strict-api-request.service";
import { AuthNetworkService } from "../../services/api/auth.network.service";
import { SelectorOption } from "./filter.interfaces";

const SelectorOptionParser = z
  .object({
    id: z.number(),
    name: z.string(),
  })
  .strict();

const PageSelectorOptionParser = createPageParser(SelectorOptionParser);

@Injectable()
export class SelectorOptionsService {
  private readonly apiRequestService: StrictApiRequestService;

  constructor(
    @Inject("API_GATEWAY_URL")
    API_GATEWAY_URL: string,
    httpClient: HttpClient,
    authNetworkService: AuthNetworkService,
  ) {
    this.apiRequestService = new StrictApiRequestService(
      `${API_GATEWAY_URL}/publication/aggregated`,
      httpClient,
      authNetworkService,
    );
  }

  public getPublishers(
    page = 1,
    size = 20,
    searchKey: string,
    campaignId: number,
  ): Promise<Page<SelectorOption>> {
    return this.getOptions(
      "/publisher-filter-options",
      page,
      size,
      searchKey,
      campaignId,
    );
  }

  public getCreators(
    page = 1,
    size = 20,
    searchKey: string,
  ): Promise<Page<SelectorOption>> {
    return this.getOptions("/creator-filter-options", page, size, searchKey);
  }

  public getCampaign(
    page = 1,
    size = 20,
    searchKey: string,
  ): Promise<Page<SelectorOption>> {
    return this.getOptions("/campaign-filter-options", page, size, searchKey);
  }

  public getOptions(
    path: string,
    page: number,
    size: number,
    searchKey: string,
    campaignId?: number,
  ): Promise<Page<SelectorOption>> {
    return this.apiRequestService
      .path(path)
      .setQueryParams({
        page: page,
        size: size,
        search: searchKey,
        campaignId: campaignId,
      })
      .setMapper(new BlankMapper<Page<SelectorOption>>())
      .setParser(PageSelectorOptionParser)
      .get();
  }
}
