import { PublicationTemplateService } from "../../data/publication-template.service";
import { RecommendedSchedule } from "../models/publication-template";

export class UpdateRecommendedScheduleInteractor {
  constructor(
    private readonly publicationTemplateService: PublicationTemplateService,
  ) {}

  public async execute(
    schedule: RecommendedSchedule,
  ): Promise<RecommendedSchedule> {
    return this.publicationTemplateService.updateRecommendedSchedule(schedule);
  }
}
