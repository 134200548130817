import { DatePipe } from "@angular/common";
import { Component, EventEmitter, input, Output } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { FilterIcon, FilterOutput, FilterType } from "../filter.interfaces";

@Component({
  standalone: true,
  selector: "app-selected-chip",
  templateUrl: "./selected-chip.component.html",
  styleUrl: "./selected-chip.component.scss",
  imports: [MatIcon, TranslateModule, DatePipe],
})
export class SelectedChipComponent {
  public filter = input.required<FilterOutput>();
  @Output() public remove = new EventEmitter<void>();
  protected readonly FilterIcon = FilterIcon;
  protected readonly FilterType = FilterType;
}
