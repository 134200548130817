// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import { DateRange } from "../services/date.service";

declare global {
  export interface Date {
    isTodayOrFutureDay: () => boolean;
    isInDayRange: (dateRange: DateRange) => boolean;
    isSameDay: (date: Date) => boolean;
  }
}

Date.prototype.isTodayOrFutureDay = function (): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return this >= today;
};

Date.prototype.isSameDay = function (date: Date): boolean {
  return (
    this.toLocaleDateString().slice(0, 10) ===
    date.toLocaleDateString().slice(0, 10)
  );
};

Date.prototype.isInDayRange = function (dateRange: DateRange): boolean {
  return (
    (this >= dateRange.startDate && this <= dateRange.endDate) ||
    this.isSameDay(dateRange.startDate) ||
    this.isSameDay(dateRange.endDate)
  );
};

export {};
