<div
  (mouseenter)="areOptionsShown.set(true)"
  (mouseleave)="areOptionsShown.set(false)"
>
  <button [ngClass]="{ active: areOptionsShown() }">
    <span class="type">
      <mat-icon>{{ FilterIcon[FilterType.TimePeriod] }}</mat-icon>
      {{ "filter." + FilterType.TimePeriod | translate }}
    </span>
    <mat-icon>chevron_right</mat-icon>
  </button>

  @if (areOptionsShown()) {
    <div class="popover">
      <div class="visible-area">
        <mat-calendar
          (selectedChange)="onSelectedChange($event)"
          [selected]="selectedDateRange"
        />
      </div>
    </div>
  }
</div>
