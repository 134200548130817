import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  signal,
} from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { NgClass } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PublicationTemplateRulesBoxComponent } from "../../../components/publication-template-rules-box/publication-template-rules-box.component";
import {
  RuleItemVM,
  RuleList,
  RulesListComponent,
} from "../../../components/publication-template-rules-box/rules-list/rules-list.component";
import { MatIconModule } from "@angular/material/icon";
import {
  InstagramStoryFields,
  getImageField,
  getVideoField,
} from "../../../data/entities/publication-template-fields.entity";

@Component({
  standalone: true,
  selector: "app-instagram-story-rules-box",
  templateUrl: "./instagram-story-rules-box.component.html",
  styleUrls: ["./instagram-story-rules-box.component.scss"],
  imports: [
    NgClass,
    MatIconModule,
    MatTabsModule,
    TranslateModule,
    RulesListComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstagramStoryRulesBoxComponent extends PublicationTemplateRulesBoxComponent<InstagramStoryFields> {
  public fields = input.required<InstagramStoryFields>();

  protected isOpen = signal(false);
  protected isDetailsOpen = signal(false);
  protected details = (
    inject(TranslateService).instant(
      "publicationTemplate.rules.instagramStory.details",
    ) as string
  )
    .split("|")
    .map((detail) => detail.trim());

  protected imageRules = computed<RuleList>(() => {
    const rules = getImageField("image", this.fields()).rules;

    return [
      new RuleItemVM(
        "category.aspectRatio",
        "instagramStory.image.aspectRatio",
        {
          aspectRatio: rules.aspectRatio.constraintPretty,
        },
      ),
      new RuleItemVM("category.width", "instagramStory.image.width", {
        min: rules.minWidth.constraint,
        max: rules.maxWidth.constraint,
      }),
      new RuleItemVM("category.format", "instagramStory.image.format", {
        format: rules.allowedMimeTypes.constraintPretty,
      }),
      new RuleItemVM("category.fileSize", "instagramStory.image.fileSize", {
        maxSize: rules.maxFileSizeInBytes.constraintPretty,
      }),
      new RuleItemVM("category.colorSpace", "instagramStory.image.colorSpace"),
    ];
  });

  protected videoRules = computed<RuleList>(() => {
    const rules = getVideoField("video", this.fields()).rules;

    return [
      new RuleItemVM(
        "category.aspectRatio",
        "instagramStory.video.aspectRatio",
        {
          aspectRatio: rules.aspectRatio.constraintPretty,
        },
      ),
      new RuleItemVM("category.audioCodec", "instagramStory.video.audioCodec", {
        codec: rules.allowedAudioCodecs.constraint.join(", "),
        maxBitrate: rules.maxAudioBitrate.constraintPretty,
        channels: rules.maxAudioChannels.constraint,
      }),
      new RuleItemVM("category.format", "instagramStory.video.format", {
        format: rules.allowedMimeTypes.constraintPretty,
      }),
      new RuleItemVM("category.frameRate", "instagramStory.video.frameRate", {
        min: rules.minFramerate.constraint,
        max: rules.maxFramerate.constraint,
      }),
      new RuleItemVM("category.fileSize", "instagramStory.video.fileSize", {
        maxSize: rules.maxFileSizeInBytes.constraintPretty,
      }),
      new RuleItemVM("category.duration", "instagramStory.video.duration", {
        min: rules.minVideoDurationInSeconds.constraint,
        max: rules.maxVideoDurationInSeconds.constraint,
      }),
    ];
  });

  protected toggle(): void {
    this.isOpen.set(!this.isOpen());
  }

  protected toggleDetails(): void {
    this.isDetailsOpen.set(!this.isDetailsOpen());
  }
}
