<div class="container" (clickOutside)="areFiltersOpen.set(false)">
  <button class="filters-button" (click)="onActionToggleFilters()">
    <span>Add Filter</span><mat-icon>add</mat-icon>
  </button>
  <div class="selected-options">
    <div class="options">
      @for (filter of activeFilters(); track $index) {
        <app-selected-chip
          [filter]="filter"
          (remove)="onActionOptionRemoved(filter)"
        />
      }
    </div>
    <button class="btn btn--content-only clear-btn" (click)="reset()">
      {{ "shared.clear" | translate }}
    </button>
  </div>
  @if (areFiltersOpen()) {
    <div class="filter-list">
      @for (filterType of filterTypes(); track filterType; let i = $index) {
        @if (filterType === FilterType.TimePeriod) {
          <app-time-period-selector
            class="filter"
            (optionSelected)="onActionOptionSelected($event)"
          />
        } @else {
          <app-autocomplete-selector
            class="filter"
            [type]="filterType"
            [interactor]="filterInteractors()[i]"
            [campaignId]="selectedCampaignId()"
            (optionSelected)="onActionOptionSelected($event)"
          />
        }
      }
    </div>
  }
</div>
